import { ActionMode } from './shared/enums/enums';
import { FromTypeEnum } from '../components/cdkvirtual/cdkvirtual.model';
export const JWT_TOKEN = '_jwt';
export const USER_SESSION = 'userSession';
export const REMEMBER_INFO = '_rememberInfo';
export const USER_INFO = '_userInfo';
export const USER_SETTINGS = 'userSettings';
export const PROPERTY_DATE = 'propertyDate';
export const PROPERTY_INFO = 'propertyInfo';
export const USE_RETAIL_INTERFACE = 'useRetailInterface';
export const VAT_ENABLED = 'VATEnabled';
export const PAYMENT_CONFIGURATION = 'paymentConfiguration';
export const PROPERTY_CONFIGURATION_SETTINGS='PROPERTYCONFIGURATION';
export const USERS_SESSSIONS_INFO='usersSessionsInfo';

export interface CommissionDetails {

  id?: number;
  itemId: number;
  categoryId: number;
  itemDescription: string;
  locationDescription: string;
  classId: number;
  flatAmount: any;
  percentage: any;
  threshold: any;
  isAfterDiscount: boolean;
  isTotalRevenue: boolean;
  isItemOnly: boolean;
  productId: number;
  outletId: number;
}


export interface CommissionDetailsUI {
  id?: number;
  itemId: number;
  categoryId: number;
  itemDescription: string;
  locationDescription: string;
  classId: number;
  flatAmount: any;
  percentage: any;
  threshold: any;
  isAfterDiscount: boolean;
  isTotalRevenue: boolean;
  isItemOnly: boolean;
  commissionon: string;
  outletId: number;
}

export interface popupConfig {
  operation: string;
  maxListOrder?: number;
  GridOperationType?: GridOperationType;
  fromScreen?: string;
}

export interface BaseResponse<T> {
  result: T;
  errorCode: number;
  errorDescription: string;
  successStatus: boolean;
  propertyId: number;
  outletId: number;
}

export enum GridOperationType {
  Create = 1,
  Edit,
  Delete,
  ListOrderDrag
}

export namespace API {

  export interface Player {
    id: number;
    firstName: string;
    lastName: string;
    pronounced: string;
    customField1: string;
    customField2: string;
    customField3: string;
    customField4: string;
    customField5: string;
    playerCategoryId: number;
    playerAddress: PlayerAddress;
    contactInformation: ContactDetails[];
    paymentReferenceId: number;
    playerLinkId: string;
    confirmationNumber: string;
    guestId: string;
    bagNumber?: string;

  }
  export interface PlayerAddress {
    playerId: number;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    state: string;
    country: string;
    zip: string;
  }

  export interface PlayerProfile {
    id: number;
    firstName: string;
    lastName: string;
    pronounce: string;
    playerProfileAddress: PlayerProfileAddress;
    playerProfileContactDetail: PlayerProfileContactDetails[];
    title: string;
    dateOfBirth: Date;

  }
  export interface PlayerProfileAddress {
    playerId: number;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    isPrivate: boolean;
  }

  export interface PlayerProfileContactDetails {
    playerId: number;
    type: number;
    value: string;
    name: string;
    isPrivate: boolean;
    isPrimary: boolean;
  }
}
export interface ContactDetails {
  id: number;
  type: number;
  value: string;
  isPrimary: boolean;
  isPrivateInfo: boolean;
  name: string;
  extension: string;
}

export namespace Tournament.UI {
  export interface TournamentInformation {
    id: number;
    allocationCode: number;
    cartFee: number;
    comments: string;
    contestPackage: number;
    course: number;
    entryFee: number;
    eventCode: string;
    eventName: string;
    eventStatus: string;
    foodAndBeveragePackage: number;
    greensFee: number;
    menTee: number;
    noOfPlayers: number;
    playFormat: number;
    playerType: number;
    playersPerGroup: number;
    rateType: number;
    selectDate: Date;
    selectTime: string;
    signUpFrom: Date;
    signUpTo: Date;
    womenTee: number;
    contactPlayerId: number;
  }


  export interface PlayerAddress {
    playerId: number;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    state: string;
    country: string;
    zip: string;
  }

  export interface ContactDetail {
    phoneType: any;
    playerId: number;
    contactTypeId: number;
    value: string;
  }

  export interface ContactInformation {
    id: number;
    firstName: string;
    lastName: string;
    pronounced: string;
    customField1: string;
    customField2: string;
    customField3: string;
    customField4: string;
    customField5: string;
    playerCategoryId: number;
    playerCategory: string;
    playerAddress: PlayerAddress;
    contactInformation: ContactDetails[];
    paymentReferenceId: number;
    playerLinkId: string;
    confirmationNumber?: string;
    balanceList?: BalanceList[];
    golfPoints?: GolfPoints[];
    cardStatus?: string,
    bagNumber?: string;
  }

  export interface GolfPoints {
    totalRounds: number;
    eligibleRounds: number;
    bucketName: string;
    bucketCode: string;
  }

  export interface BalanceList {
    AccrualPoint: number;
    AccrualCash: number;
    BucketName: string;
    BucketCode: string;
  }

  export interface Tournament {
    TournamentInformation: TournamentInformation;
    contact: ContactInformation;
  }

  export interface TournamentDetails {
    tournamentId: number
    eventDate: string;
    eventCode: string;
    eventName: string;
    course: string;
    allocationCode: string;
    tournamentStatus: string;
    eventOriginalDate: Date;
  }

  export class SearchFilter {
    FromDate: Date;
    ToDate: Date
  }

  export interface SearchData {
    TournamentId: number;
  }
}

export enum ProductApplicationType
{
    None = 0,
    SinglePms_Snc = 16,
    MultiPms_Snc = 14,
    StandAlone_Snc=17,
    CateringOnly_Snc=18
}

export enum SearchType {
  Guest = 1,
  Player,
  GuestAndPlayer,
  GuestAndConfNo,
  ConfNo,
  Member
}

export interface SelectedProducts {
  ItemId: number,
  ExternalPOSItemId: number,
  ItemDescription: string,
  ServiceId: number,
  SalesPrice: number,
  isEditDisabled?: boolean,
  isModificationRestricted?: boolean,
  ProductName: string,
  ProductPrice: number,
  Noofitems: number,
  Discount: number,
  DiscountTypeId: number,
  DiscountPercentage: number,
  category: number;
  Commission?: Commission[],
  Gratuity?: Gratuity,
  ServiceCharge?: ServiceCharge,
  isCommissionable?: boolean,
  isSettleTicket?: boolean,
  isGroupingKey: boolean,
  isPackagedItem: boolean,
  PackageItemId: number,
  MultiPack: boolean,
  ClientMultiPackId: number,
  PackageGroupId: number,
  LineNumber?: number;
  Tax?: number,
  isOpenPricedItem: boolean,
  transactionDetailLinkId?: number,
  id?: number,
  charges?: any,
  StaffTransactionDetail?: StaffTransactionDetail[]
  MaxTherapistForCommission?: number;
  CheckOutAppointmentId?: number;
  IsAppointmentNotCheckedOut?: boolean;
  scaledUnits?: number;
  unitOfMeasureId?: number;
  uom?: string;
  isReturn?: boolean;
  isTaxExempt?: boolean;
  retailItemType?: RetailItemType;
  payeeId?: number,
  playerName?: string;
  multipackExternalPOSItemId?: number;
}

export interface Commission {
  id: any,
  StaffId: number,
  StaffType: string,
  Quantity?: number // for multi therapist commission for same item(not split commission)
  TemplateId?: number;
  SplitLevel?: number;
  SplitPercentage?: number;
}

export interface StaffTransactionDetail {
  StaffId: number
  StaffType: string
}

export enum RetailItemType {
  RetailItemRetailPOSOnly = 1,
  RetailItemAvailableForSpaPackages,
  SpaServices,
  SpaPackage,
  AppointmentAddon,
  Deposit,
  RentalItem,
  InternalGiftCard,
  ExternalGiftCard,
  RevenueItem,
  CustomFee,
  CancellationFee,
  NoShowFee,
  PMSAddOnsRentalItem = 14
} 

export interface Gratuity {
  Id: any;
  TransactionDetailId: number;
  TherapistId: any;
  Percentage: number;
  PercentageId: number;
  PercOrAmount: number; // Percentage - 1 , Amount - 2
  Amount: number;
  gratuity: number;
  StaffType?: any;
}

export interface ServiceCharge {
  Id: any;
  TransactionDetailId: number;
  TherapistId: any;
  Percentage: number;
  PercentageId: number;
  PercOrAmount: number;
  Amount: number;
  ServiceCharge: number;
  StaffType?: any;
}

export interface ScheduledTeeTimeUnPaidPlayer {
  id?: number;
  scheduledTeeTimeId: number;
  playerId: number;
  playerName: string;
  playerType: string;
  playerSlotPosition: number;
  scheduledDateTime: string;
  scheduledAPIDateTime?: string;
  course: string;
  rateTypeId: number;
  rateType: string;
  packageCode: string;
  walk: number;
  trail: number;
  holes: number;
  greenFee: number;
  cartFee: number;
  entryFee: number;
  cartFeeRetailItemId: number;
  greenFeeRetailItemId: number;
  entryFeeRetailItemId: number;
  tournamentId: number;
  otherItems: TournamentPackageItem[];
  cartFeeRetailItem: DomainRetailItem;
  greenFeeRetailItem: DomainRetailItem;
  entryFeeRetailItem: DomainRetailItem;
  deposits: Deposit[];
  checked?: boolean;
  playerPosition?: number;
  isTournamentPlayersUnPaid?: boolean;
  ticketNumber?: string;
  playerCategoryId?: number;
  playerLinkId?: string;
}

export interface ACESItemInfo {
  ItemGroupName: string;
  ItemGroupID?: number;
  ItemPrice?: number;
  Quantity?: number;
}


export interface ACESCheckInfo {
  CheckNumber: string;
  PaymentAmount: number;
  TaxAmount: number;
  Amount: number;
  ItemInfo: ACESItemInfo[];
}
export interface ACESTenderInfo {
  TenderType: string;
  Amount: number;
}
export interface ACESPaymentInfo {
  TotalAmount?: number;
  TenderInfo: ACESTenderInfo[];
}

export interface ACESPayment {
  SourceCorpId: number,
  SourceSiteId: number,
  SourceSystemCode?: string,
  GuestCardNo: number,
  Pin?: number,
  CheckInfo: ACESCheckInfo,
  PaymentInfo: ACESPaymentInfo,
  NumberofRounds?: number,
  RateType?: string,
  PlayerType?: string,
  Course?: string
}


export interface ACESPaymentRecord {
  paymentTransactionId: number,
  transactionId: string,
  acesTransactionid: string,
  memberId: number,
  tenderType: string,
  amount: number,
  paymentMethodType: number,
  memberPin: string
}


export interface Course {
  id: number;
  name: string;
}

export interface TournamentPackageItem {
  tournamentId: number;
  tournamentPackageId: number;
  tournamentComponentId: number;
  retailItemId: number;
  retailItemDetail: DomainRetailItem;
  price: number;
}

export interface Deposit {
  id?: number;
  playerId: number;
  amount: number;
  gratuity?: number;
  serviceCharge?: number;
  refundAmount?: number;
  depositTransactionId: number;
  refundTransactionId?: number;
  isVoided?: boolean;
}

export interface DomainRetailItem {
  retailItemDetail: RetailItem;
  retailItemTax: RetailItemTax[];
}

export interface RetailItemTax {
  id: string | number;
  retailItemId: string | number;
  taxName: string;
}

export interface RetailItem {
  id: string | number;
  itemNumber: string;
  itemDescription: string;
  salesPrice: number;
  category: number;
  categoryName: string;
  subCategory1Name: string;
  subCategory2Name: string;
  subCategory3Name: string;
  subCategory4Name: string;
  subCategory5Name: string;
  subCategory1: string;
  subCategory2: string;
  subCategory3: string;
  subCategory4: string;
  subCategory5: string;
  costPrice: number;
  barCode: string;
}

export interface Outlet {
  id: number;
  externalPOSId?: number;
  outletName: string;
  outletNumber: number;
  isActive: boolean;
  terminalId?: number;
}

export enum AlertType {
  Success = 1,
  Warning = 2,
  Error = 3,
  WellDone = 4,
  Info = 5,
  AccessDenied = 6,
  Done = 7,
  Confirmation = 8
}

export enum AlertAction {
  CONTINUE = 'CONTINUE',
  CANCEL = 'CANCEL',
  YES = 'YES',
  NO = 'NO'
}

export enum ButtonType {
  YesNo = 1,
  YesNoCancel = 2,
  OkCancel = 3,
  Ok = 4,
  SaveCancel = 5,
  Continue = 6,
  AddCancel = 7,
  ContinueCancel = 8,
  viewFailedCancel = 9
}

export interface PlayerTransaction {
  id: number;
  playerId: number;
  transactionId: number;
  ticketNumber: string;
  greenFeeTax: number;
  cartFeeTax: number;
  entryFeeTax: number;
  amountPaid: number;
  remainingDue: number;
  playerStatus: PlayerPaymentstatus;
  status: PlayerTransactionStatus;
}

export interface PlayerTransactionDetail {
  id: number;
  playerId: number;
  playerName: string;
  transactionId: number;
  transactionDetailId: number;
  playerTransactionId: number;
  greenFeeTax: number;
  cartFeeTax: number;
  entryFeeTax: number;
}

export enum PlayerPaymentstatus {
  booked = 1,
  noShow = 2,
  cancelled = 4,
  paid = 8,
  unPaid = 16,
  refund = 32,
  checkIn = 64,
  checkOut = 128
}

export class Course {
  id: number;
  name: string;
  defaultOutletId: number;
  avgPlay9Holes: number;
  avgPlay18Holes: number;
  isActive = false;

}

export interface DefaultUserConfiguration {
  id?: number;
  userId: number;
  defaultOutletId?: number;
  defaultTerminalId?: number;
  defaultCourseId?: number;
  defaultPaymentDevice?: string;
  defaultDeviceName?: string;
  isIdtechSred?: boolean;
  smallStickersPrinter?: string;
  hangingTicketsPrinter?: string;
  defaultPaymentId?: number;
}

export interface RainCheckIssue {
  id: number;
  issuedBy: string;
  issuedDate: Date;
  issuedDateDisplay?: string;
  playerId: number;
  playerName: string;
  greenFee: number;
  cartFee: number;
  greenFeeTax: number;
  cartFeeTax: number;
  holeNumber: number;
  rainCheckValue: number;
  teeTimeId: number;
  courseId: number | string;
  courseName: string;
  ticketNumber: number;
  outletName: string;
  isSettled: boolean;
}

export interface UserCourseConfiguration {
  id: number;
  userId: number;
  courseId: number;
}

export interface UserConfiguration {
  userGolfConfiguration: UserGolfConfiguration;
  userCourseConfiguration: UserCourseConfig[];

}

export interface UserGolfConfiguration {
  id: number;
  userId: number;
  daysOut: number;
}

export interface UserCourseConfig {
  id: number;
  userId: number;
  courseId: number;
}

export interface AvailableDay {
  id: number;
  name: string;
  description: string;
  value: string;
  disabled?: boolean;
}

export interface RateSetupData {
  id: number;
  courseId: number;
  rateTypeId: number;
  startDate: string; // clarification
  endDate: string; // clarification
  startTime: string; // clarification
  endTime: string; // clarification
  dayOfWeek: string[];
  greenFee: number;
  cartFee: number;
  entryFee: number;
  overrideCourseTeeFee: boolean;
  greenFeeRetailItemId: number;
  cartFeeRetailItemId: number;
  entryFeeRetailItemId: number;
}

export interface RateType {
  id: number;
  type: string;
  guaranteeType: string;
  isActive: boolean;
  isSupressReceipt: boolean;
  listOrder: number;
}


export interface LocalizedMonthsModel {
  id: number;
  short: string;
  long: string;
  code: string;
}


export interface localizationJSON {
  Tournament: any;
  common: any;
  calendar: Calendar;
  home: any;
  teetime: any;
  guest: any;
  header: any;
  shop: any;
  lessons: any;
  lostfound: any;
  reports: any;
  settings: any;
  breakpoint: any;
  allocationblock: any;
  userConfig: any;
  teetimeIntervalConfig: any;
  tournamentformat: any;
  tournamentScore: any;
  subMenu: any;
  customField: any;
  tournamentContact: any;
  dashBoard: any;
  teeActions: any;
  contactTypes: any;
  dayend: any;
  lesson: any;
  lessonBooking: any;
  virtualTable: any;
  lostandfound: any;
  guestTable: any;
  tooltips: any;
  resolutionNotFound: any;
  GroupBookTeeTime: any;
  alertPopup: any;
  PlayerPaymentStatus: any;
  commonItinerary: any;
}

export interface Calendar {
  Sun: string;
  Mon: string;
  Tue: string;
  Wed: string;
  Thu: string;
  Fri: string;
  Sat: string;
  Sunday: string;
  Monday: string;
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  Saturday: string;
  January: string;
  February: string;
  March: string;
  April: string;
  May: string;
  June: string;
  July: string;
  August: string;
  September: string;
  October: string;
  November: string;
  December: string;
  Jan: string;
  Feb: string;
  Mar: string;
  Apr: string;
  Jun: string;
  Jul: string;
  Aug: string;
  Sep: string;
  Oct: string;
  Nov: string;
  Dec: string;
}

export interface DaysModel {
  id: number;
  short: string;
  long: string;
  code: string;
  longCode?: string;
}

export interface TransactionDetailAggregate {
  playerTransactionDetails: PlayerTransactionDetail[];
  teeTicketDetail: TeeTicketDetail;
}

export interface TeeTicketDetail {
  courseId: number;
  scheduleDateTime: Date;
  scheduledTeeTimeIds: number[];
}

export enum PlayerTransactionStatus {
  Pending = 1,
  Closed
}

export interface ModalInput {
  mode: ActionMode | FromTypeEnum;
  actionButton: string;
  form: any;
  isCopy?: boolean;
}

export interface BuildingRoomTypeInput {
  buildingList: BuildingRadioInput[];
  roomsList: BuildingRoomTypeRadioInput[];
  sectionOneTitle?: string;
  sectionTwoTitle?: string;
}
export interface BuildingRadioInput {
  id: number;
  checked: boolean;
  selectedRooms: BuildingRoomTypeRadioInput[];
  value: string | number;
  viewValue: string;
}

export interface BuildingRoomTypeRadioInput {
  id: number;
  checked: boolean;
  value: string | number;
  viewValue: string;
  outletId?: number;
  disabled?: boolean;
}
export interface BuildingRoomMappingInfo {
  buildingId: number;
  retailItemId?: number;
  outletId?: number;
}
export interface UserMachineInfo {
  userId: number,
  userPropertiesMachineInfo: UserPropertiesMachineInfo[];
}
export interface UserPropertiesMachineInfo {
  propertyId: number;
  userDefault: UserDefaultFormat;
  miscConfiguration: MiscellaneousConfiguration;
  machineNames: MachineName[];
}

export interface SystemConfiguration {
  id: number;
  switchId: number;
  switch: string;
  switchType: string;
  value: string | boolean;
  isActive: boolean;
  moduleId: number;
  propertyId: number;
}

export interface MachineName {
  id: number;
  propertyId: number;
  name: string;
  isActive: boolean;
  listOrder: number;
  defaultGolfCourse?: number;
  defaultOutlet?: number;
}

export interface SearchDropdownOptions {
  id: string | number;
  viewValue: string;
  searchPlaceHolder?: string;
}

export enum PreviewReportPrintOptions {
  single = 1,
  separate = 2,
  category =3
}
export interface PrintCardResponse {
  fileType: number,
  name: string,
  value: string;
  action: number,
  isEmailSent: boolean,
  errorCode: number

}

export enum CardPrinterTypes{
  ZebraPrinter = 'Zebra Printer'
}

export interface MiscellaneousConfiguration {
  enableTransactionByMachineName: boolean,
  promptOnLogin: boolean,
  printerManagerURI?: string,
  enableCardPrint?: boolean,
  cardPrinterType?: string
}

export interface UserDefaultFormat {
  defaultMachineId: number
}
export interface DefaultUserConfigurationTenant<T> {
  id: number;
  propertyId: number;
  productId: number;
  configurationName: string;
  configValue: DefaultUserConfigurationTenantModel
  defaultValue: T;
  lastModifiedBy: T;
  lastModifiedDate: Date;
  userId?: number;
}

export interface DefaultUserConfigurationTenantModel {
  defaultMachineId: number
}

export enum PrintType {
  Html,
  Pdf,
  Word
}
export class GuestPolicyDetail {
  id: string;
  consentDate: Date;
  consentExpiryDate: Date;
  consentPolicyId: number;
  comments: string;
  isPurged: boolean;
  policyCategoryType: PolicyCategoryType;
  lastName?:string;
  phone?:Phone[];
  email?:Email[];
}
export interface Phone {
  contactType: string,
  phoneType: number;
  phoneLabel: string;
  countryCode:string | number;
  phoneNumber: string;
  extension: string;
  privateInformtionPhone: boolean,
  primaryPhone: boolean,
  platformContactUuid?: string
}
export interface Email {
  contactType: string,
  emailType: number;
  emailLabel: string;
  emailId: string;
  primaryEmail: boolean,
  privateInformtionEmail: boolean,
  platformContactUuid?: string
}

export interface GuestExportNotificationModel
{
  guestId: string;
  fromName: string;
  contactPhone: string;
  contactEmail: string;
  mailBody: string;
  mailSubject: string;
  isSendEmail: boolean;
  isSendSMS: boolean;
  attachmentContent: string;
}

export enum PolicyType {
  DataRetentionPolicy = 1,
  ConsentPolicy
}
export enum PolicyCategoryType {
  Guest = 1,
  Contact,
  GolfPlayer,
  ARAccount,
  Member,
  Condo
}
