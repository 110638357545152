
export const COMPRESSION_LIMIT = 500;
export const IMAGE_MAXWIDTH = 280;
export const IMAGE_MAXHEIGHT = 280;
export const PHONE_LENGTH = 10;
export const ALLOWED_IMAGE_SIZE = 2;
export const MISCELLANEOUSCONFIGURATION = "MISCCONFIGURATION";
export const DEFAULTCONFIGURATION = "DEFAULTCONFIGURATION";
export const PMSINTEGRATIONCONFIGURATION = "PMSINTEGRATIONCONFIGURATION";
export const PROPERTYDIFFERENTIATORCONFIGURATION = "PROPERTYDIFFERENTIATORCONFIGURATION";
export const CUSTOMERID_LENGTH = 10;
export const NO_OF_DECIMAL_DIGITS = "NoOfDecimalDigits";
export const EXTERNALGUESTINTERFACENAME = "ExternalGuestInterfaceName";
export const EXTERNALACTIVITYINTERFACENAME = "ExternalActivityInterfaceName";
export const PMSSSYSTEM = "PMSSystem";
export const AUTOINVOICE = "AutoInvoice";
export const AutoARInvoice = "Auto-ARInvoice";
export const NotifyItineraryADM = "ADM-Notify-Guest-Itinerary";
export const API = "API";
export const UserErrorMessage =
  "An internal error occured during your request!";
export class HttpResponseConstants {
  public static UNKNOWN_ERROR: string = "Unknown Error";
}

export class Services {
  public static PrintManager: string = "V1PrintManager";
}
export enum InternalIntegrationSettingData {
  InterfaceModuleName = "INTERFACES",
  ScreenNameSpaIntegration = "SPAINTEGRATION",
  ScreenNameGolfIntegration = "GOLFINTEGRATION",
  ScreenNameSNCIntegration = "SNCINTEGRATION",
  ScreenNameSeatIntegration = "SEATINTEGRATION",
  ScreenNameEstablishedAccountingIntegration = "ESTABLISHEDACCOUNTINGINTEGRATION",
  ScreenNameIGIntegration = "IGINTEGRATION",
  ScreenNameDMIntegration = "DATAMAGINEINTEGRATION",
  ScreenNameSamsotechIntegration = "SAMSOTECHINTEGRATION",
  ScreenNameAcitivityIntegration = "ACTIVITYINTEGRATION",
  ScreenNameDiningIntegration = "DININGINTEGRATION",
  PropertyCode = "PropertyCode",
  TenantCode = "TenantCode",
  AuthServiceURL = "AuthServiceURL",
  ApplicationURL = "ApplicationURL",
  GatewayURL = "GatewayURL",
  EnableRollUpByDefault = "EnableRollUpByDefault",
  EnableIGIntegration = "EnableIGIntegration",
  AccountingPropCode = "AccountingPropCode",
  TenantId = "TenantId",
  Client = "Client",
  ClientSecret = "ClientSecret",
  ProductId = "ProductId",
  PropertyId = "PropertyId",
  EnterpriseManagementURL = "EnterpriseManagementURL",
  SPASheduleServiceURL = "SPASheduleServiceURL",
  GolfSheduleServiceURL = "GolfSheduleServiceURL",
  IdentityServerURL = "IdentityServerURL",
  BaseApiURL = "BaseApiURL",
  IsCrossPlatform = "IsCrossPlatform",
  DataMagineIdentityService = "dmidp",
  DataMagineIndexingService = "dmind",
  DataMagineSigCapService = "dmsigcap",
  DocTypesRmDef = "dmbasrmdef_doctypes",
  DocTypesBk = "dmbooking_doctypes",
  DocTypesFile = "dmfilecard_doctypes",
  DocTypesGuest = "dmguestrec_doctypes",
  DocTypesInt = "dmmaint_doctypes",
  DocTypesRmShot = "dmrmshot_doctypes",
  DocTypesReg = "dmreg_doctypes",
  DocTypesStay = "dmstayrec_doctypes",
  IndexRmDef = "dmbasrmdef_index",
  IndexBk = "dmbooking_index",
  IndexFile = "dmfilecard_index",
  IndexGuest = "dmguestrec_index",
  IndexInt = "dmmaint_index",
  IndexRmShot = "dmrmshot_index",
  IndexReg = "dmreg_index",
  IndexStay = "dmstayrec_index",
  ScreenNameACCTIntegration = "",
  AgilysysAccountingIntegrationIdentityService = "AgilysysAccountingIntegrationIdentityService",
  AgilysysAccountingIntegrationSigCapService = "AgilysysAccountingIntegrationSigCapService",
}

export enum CustomAlertScreen {
  Cancel = "Cancel",
}
export const Menu = {
  unPaidPlayerElementId: 2022,
};
export class AccountingUrl {
  public static accountingService: string = "/AccountingService/";
}
export class FolioConstants {
  public static folioService: string = "/FolioService/";
}

export enum CustomDateConstants {
  longDayshortMonth = "Thursday Dec 20 2012",
  shortDayShortMonth = "Thu Dec 20 2012",
  longDayLongMonth = "Thursday December 20 2012",
  shortDayLongMonth = "Thu December 20 2012",
  shortMonth = "Dec 20 2012",
  dateWithShortMonth = "20 Dec 2012",
  longMonth = "December 20 2012",
}
export enum CustomDateWithTimeConstants {
  longDayshortMonthWithTime = "Thursday Dec 20 2012 08:30:00",
  shortDayShortMonthWithTime = "Thu Dec 20 2012 08:30:00",
  longDayLongMonthWithTime = "Thursday December 20 2012 08:30:00",
  shortDayLongMonthWithTime = "Thu December 20 2012 08:30:00",
  shortMonthWithTime = "Dec 20 2012 08:30:00",
  longMonthWithTime = "Dec 20 2012 08:30:00",
}

export enum CustomDateWithTimeConstantsTimeZone {
  shortMonthWithTime = "Dec 20 2012 08:30:00",
}
export enum CustomAddTimetoDate {
  DateWithTime = "dateWithTimebject",
  DateWithoutTime = "dateWithoutTimebject",
}
export enum CustomToApi {
  DateWithHHMMSS = "YYYY-MM-DDTHH:mm:ss",
  DateWithHHMM = "YYYY-MM-DDTHH:mm",
}
export const enum CompareKey {
  invalid = -1,
  equal = 0,
  lesser,
  greater,
}
export const enum PasscodeKeyTypes {
  Passcode = "Passcode ",
  QuickId = "Quick Id",
  ManagerAuth = "Manager Authorization",
}
export enum PasscodeTypes {
  Warning = 0,
  Passcode = 1,
  QuickId = 2,
  ManagerAuth = 3,
}
export const enum ErrorType {
  required,
  pattern,
  invalid,
}

export namespace RetailConstants {
  export const InvokeAgilysysPayViaWebProxy = "InvokeAgilysysPayViaWebProxy";
  export const CancellationTimeInSeconds = "CancellationTimeInSeconds";
  export const RGUEST_DEVICE_SWIPE_TIMEOUT = 90 * 1000;
  export const VERSA = 'VERSA';
}

export const enum GiftCardTransactionType {
  Issue = 1,
  Load,
  UnLoad,
  CashBack,
  Redemption,
}

export const StatusMessages: { [key: number]: string } = {
  0: "No response", // No response
  101: "Switching Protocols", // Switching Protocols
  400: "Bad Request", // Bad Request
  401: "Unauthorized", // Unauthorized
  404: "Not Found", // Not Found
  405: "Method Not Allowed", // Method Not Allowed
  409: "Conflict", // Conflict
  415: "Unsupported Media Type", // Unsupported Media Type
  422: "Unprocessable Content", // Unprocessable Content
  440: "Session Expired or Login Time-out", // Session Expired or Login Time-out
  499: "Token Required", // Token Required
  502: "Bad Gateway", // Bad Gateway
  503: "Service Unavailable", // Service Unavailable
  504: "Gateway Timeout", // Gateway Timeout
  500: "Internal Server Error", // Internal Server Error
};
