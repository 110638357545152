import { Injectable, EventEmitter } from '@angular/core';
import { FeaturesConfigurations, PropertyFeature } from '../business/property-features.model';
import { HttpServiceCall, HttpMethod } from './http-call.service';
import * as GlobalConst from '../../shared/globalsContant';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { BaseResponse } from '../shared.modal';
import { Subject } from 'rxjs';
import { DeviceDetails, GatewayConfiguration } from './payment/payment-model';
import { DefaultBucketName, MiscellaneousSwitch, GatewayDeviceConfiguration } from 'src/app/retail/shared/globalsContant';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { GatewayDeviceConfigurationStore } from '../../shared/service/payment/payment-model';
import { RetailCommunication } from '../../retailcommunication.service';
import { RetailRoutes } from '../../retail-route';
import { PaymentMethod } from './payment/payment-business.model';
import { RetailUtilities } from '../utilities/retail-utilities';
import { PayAgentService } from "src/app/retail/shared/service/payagent.service";
import { NonPMAgentRequestHandler } from 'src/app/retail/payment/NonPMAgentRequestHandler';
import { PaymentMethods, HandleRequest, HandleResponse } from '../business/shared.modals';
import { NonIntegratedCreditCardConfiguration } from './payment/payment-model';
@Injectable({ providedIn: 'root' })
export class RetailFeatureFlagInformationService {

    propFeature: Subject<PropertyFeature[]> = new Subject();
    private readonly payAgentURI: string = 'PAYAGENTURI';
    //CMS Related Feature flags
    private _allowCompRedemptionVoid: boolean;
    public get AllowCompRedemptionVoid() {
        return this._allowCompRedemptionVoid;
    }

    private _allowOfferRedemptionVoid: boolean;
    public get AllowOfferRedemptionVoid() {
        return this._allowOfferRedemptionVoid;
    }

    private _allowOfferRedemption: boolean;
    public get AllowOfferRedemption() {
        return this._allowOfferRedemption;
    }


    private _offerRedeemAtCheckout: boolean;
    public get OfferRedeemAtCheckout() {
        return this._offerRedeemAtCheckout;
    }

    public get AllowAccrualPoints() {
        return sessionStorage.getItem('allowAccrualPoints')?.toLowerCase() === 'true' ? true : false;
    }

    private _isAllowSurcharge: boolean
    public get isAllowSurcharge() {
        return this._isAllowSurcharge;
    }

    private _omsOfferVoidConfig: OMS_OfferVoidConfig[] = [];
    public get CMS_Wise_VoidConfig() {
        return this._omsOfferVoidConfig;
    }

    private _updateGuestInfoAsPerCMS: boolean;
    public get UpdateGuestInfoAsPerCMS() {
        return this._updateGuestInfoAsPerCMS;
    }

    private _isCMSConfigured: boolean = false;
    public get IsCMSConfigured() {
        return this._isCMSConfigured;
    }

    private _isCOMPConfigured: boolean = false;
    public get IsCOMPConfigured() {
        return this._isCOMPConfigured;
    }

    private _cmsSiteId: string = "";
    public get GetCMSSiteId() {
        return this._cmsSiteId;
    }

    private _cmsProfitCenterId: number = 0;
    public get CMSProfitCenterId() {
        return this._cmsProfitCenterId;
    }

    private _cmsProfitCenterName: string = "";
    public get CMSProfitCenterName() {
        return this._cmsProfitCenterName;
    }

    private _cmsRequirePin: boolean = false;
    public get CMSRequirePin() {
        return this._cmsRequirePin;
    }

    private _cmsApplicableBuckets: string = "";
    public get CMSApplicableBuckets() {
        return this._cmsApplicableBuckets;
    }

    private _cmsCurrencyName: string = "";
    public get CMSCurrencyName() {
        return this._cmsCurrencyName;
    }

    private _zoneOffset: string = "";
    public get ZoneOffset() {
        return this._zoneOffset;
    }

    private _gatewayType: string = "";
    public get GatewayType() {
        return this._gatewayType;
    }

    private _isEatecConfigured: boolean = false;
    public get IsEatecConfigured() {
        return this._isEatecConfigured;
    }

    private _isEatecEnabled: boolean = false;
    public get IsEatecEnabled() {
        return this._isEatecEnabled;
    }

    private _isLockersEnabled: boolean = false;
    public get IsLockersEnabled() {
        return this._isLockersEnabled;
    }

    private _includeLockerAssignment: boolean = false;
    public get includeLockerAssignment() {
        return this._includeLockerAssignment;
    }
    private _isRentalEnabled: boolean = false;
    public get IsRentalEnabled() {
        return this._isRentalEnabled;
    }

    private _isDataExpressEnabled: boolean = false;
    public get IsDataExpressEnabled() {
        return this._isDataExpressEnabled;
    }

    private _isRetailIcEnabled: boolean = false;
    public get IsRetailIcEnabled() {
        return this._isRetailIcEnabled;
    }
    private _isARAccountPostingConfigured: boolean = false;
    public get IsARAccountPostingConfigured() {
        return this._isARAccountPostingConfigured;
    }

    private _SenderCode: string = "";
    public get SenderCode() {
        return this._SenderCode;
    }

    private _PrePopulateMemberAR: string = "";
    public get PrePopulateMemberAR() {
        return this._PrePopulateMemberAR;
    }

    private _profitCenter: string = "";
    public get ARAcctProfitCenter() {
        return this._profitCenter;
    }

    private _cmsSearchType: string = "";
    public get CMSSearchType() {
        return this._cmsSearchType;
    }

    private _v1GiftcardRGuestPayURL: string = "";
    public get V1GiftCardRGuestPayURL() {
        return this._v1GiftcardRGuestPayURL;
    }

    private _allowPinValidation: boolean = false;
    public get AllowPinValidation() {
        return this._allowPinValidation;
    }

    private _pmsSystem: string = "";
    public get PMSSystem() {
        return this._pmsSystem;
    }

    private _IsPostViaPMSCommunicationReceiver: string = "";
    public get PostViaPMSCommunicationReceiver() {
        return this._IsPostViaPMSCommunicationReceiver;
    }

    private _groupChargePMSSystem: PmsSystemType = "";
    public get GroupPMSSystem() {
        return this._groupChargePMSSystem;
    }

    private _groupHideSearchByGroupName: boolean = false;
    public get GroupHideSearchByGroupName() {
        return this._groupHideSearchByGroupName;
    }
    private _supportLoadReversal: boolean = true;
    public get SupportLoadReversal() {
        return this._supportLoadReversal;
    }

    private _supportIssueReversal: boolean = true;
    public get SupportIssueReversal() {
        return this._supportIssueReversal;
    }

    private _cardDataSource = "";
    public get CardDataSource() {
        return this._cardDataSource;
    }

    private _cardDataRegex = "";
    public get CardDataRegex() {
        return this._cardDataRegex;
    }

    private _supportCompSlipRedemption: boolean = false;
    public get SupportCompSlipRedemption() {
        return this._supportCompSlipRedemption;
    }

    private readonly DEFAULT_MAX_CARDS_PER_TRANS = 6;
    private _v1GiftcardMaxLimit: number = this.DEFAULT_MAX_CARDS_PER_TRANS;
    public get V1GiftcardMaxLimitPerTransaction() {
        return this._v1GiftcardMaxLimit;
    }

    private _externalGiftcardMaxLimit: number = this.DEFAULT_MAX_CARDS_PER_TRANS;
    public get ExternalGiftcardMaxLimitPerTransaction() {
        return this._externalGiftcardMaxLimit;
    }

    private _skipPMS_XML: boolean = false;
    public get SkipPMSXML() {
        return this._skipPMS_XML;
    }

    public get SkipPMAgentCMS() {
        return sessionStorage.getItem('WebCommunicationProxyVersionCheck')?.toLowerCase() === 'true' ? true : false;
    }

    private _gatewayDeviceConfiguration: GatewayDeviceConfigurationStore[] = [];
    public get gatewayDeviceConfig() {
        return this._gatewayDeviceConfiguration;
    }

    private _memberDiscountComment: string = ""
    public get MemberDiscountComment() {
        return this._memberDiscountComment;
    }

    private _memberDiscountReason: string = ""
    public get MemberDiscountReason() {
        return this._memberDiscountReason;
    }

    private _applicableCorporates: string = ""
    public get ApplicableCorporates() {
        return this._applicableCorporates;
    }

    private _corpIdNameMap: string = ""
    public get CorpIdNameMap() {
        return this._corpIdNameMap;
    }

    public get IsResortFinanceEnabled() {
        return sessionStorage.getItem('enableResortFinance')?.toLowerCase() === 'true' ? true : false;
    }
    
    private _multipleOfferSupport: boolean = false
    public get MultipleOffersupport() {
        return this._multipleOfferSupport;
    }

    private _allowDuplicateDescription: boolean = false;
    public get AllowDuplicateDescription() {
        return this._allowDuplicateDescription;
    }

    
    private _giftcardIssueanceSupportedByVendor: boolean = true;
    /**
     * Configuration Key to Configure whether the Giftcard Issueance is supported or not, by default it is true as Most of the Giftcard Gateways support Issueance
     */
    public get GiftcardIssuanceSupportedByVendor() {
        return this._giftcardIssueanceSupportedByVendor;
    }

    private _initiateLoadForCardActivation: boolean = false;
    /**
     * Configuration Key to Configure whether to invoke load API to activate the Giftcard or not, by default it is false
     */
    public get InitiateLoadForCardActivation() {
        return this._initiateLoadForCardActivation;
    }

    private _errorMessageForInactiveGiftCards: string = "";
    /**
     * Configuration Key to Configure the Error Message to be compared for Inactive Giftcards
     */
    public get ErrorMessageForInactiveGiftCards() {
        return this._errorMessageForInactiveGiftCards;
    }

    private _validateCardNumber: boolean = false;
    /**
     * Configuration Key to Configure 
     */
    public get ValidateGiftCardNumber() {
        return this._validateCardNumber;
    }

    /**
     * Use this Property only to identify if a property is opted to Skip PMAgent
     */
    get SkipPMAgent() { return this.IsSkipPMAgent(null) }

    public v1GiftcardSwipeTimeout = 30000;
    public v1GiftcardPoolingTime = 300;

    private _refreshConfigTriggered = false;
    RefreshConfigTriggered = new EventEmitter<any>();
    GetFeaturesCompleted = new EventEmitter<any>();
    LogoutTriggered = new EventEmitter<any>();
    /** Assign the Public Values */
    $scope: PayAgentService | NonPMAgentRequestHandler;
    constructor(private _localization: RetailLocalization
        , private http: HttpServiceCall
        , private _propertyInfo: RetailPropertyInformation
        , public retailCommunication: RetailCommunication
        , private _utils: RetailUtilities
    ) { }

    reset(): void {
        this.LogoutTriggered.emit();
        this._refreshConfigTriggered = false;
        this._allowCompRedemptionVoid = false;
        this._allowOfferRedemptionVoid = false;
        this._allowOfferRedemption = false;
        this._offerRedeemAtCheckout = false;
        this._updateGuestInfoAsPerCMS = false;
        this._isCMSConfigured = false;
        this._zoneOffset = "";
        this._gatewayType = "";
        this._isEatecConfigured = false;
        this._isEatecEnabled = false;
        this._includeLockerAssignment = false;
        this._isRentalEnabled = false;
        this._isDataExpressEnabled = false;
        this._isARAccountPostingConfigured = false;
        this._SenderCode = "";
        this._profitCenter = "";
        this._v1GiftcardRGuestPayURL = "";
        this._pmsSystem = "";
        this._IsPostViaPMSCommunicationReceiver = "";
        this._supportLoadReversal = true;
        this._supportIssueReversal = true;
        this._cmsSiteId = "";
        this._cmsProfitCenterId = 0;
        this._cmsProfitCenterName = "";
        this._cmsCurrencyName = "";
        this._omsOfferVoidConfig = [];
        this._groupChargePMSSystem = "";
        this._groupHideSearchByGroupName = false;
        this._skipPMS_XML = false;
        this._isAllowSurcharge = false;
        this._gatewayDeviceConfiguration = [];
        this.$scope = null;
        this._applicableCorporates = "";
        this._corpIdNameMap = "";
        this._cardDataRegex = "";
        this._cardDataSource = "";
        this._allowDuplicateDescription = false;
        this._isLockersEnabled = false;
        this._multipleOfferSupport = false;
        this._initiateLoadForCardActivation = false;
        this._giftcardIssueanceSupportedByVendor = true;
        this._errorMessageForInactiveGiftCards = "";
        this._validateCardNumber = false;
    }

    RefreshConfig() {
        if (!this._refreshConfigTriggered) {
            this.GetPropertyFeatures().then((propFeatureList: PropertyFeature[]) => {
                this.propFeature.next(propFeatureList);
                if (!propFeatureList) {
                    this.GetFeaturesCompleted.emit();
                    return;
                }
                const ActivefeatureList = propFeatureList.filter(p => p.isActive);
                sessionStorage.setItem('PropertyFeatureConfigurations', JSON.stringify(ActivefeatureList));
                if (ActivefeatureList && ActivefeatureList.length > 0) {
                    this.GetFeatureConfigurations(ActivefeatureList).then((response) => {
                        ActivefeatureList.forEach(x => {
                            const config = response.filter(y => y && y.propertyFeatureId == x.id);
                            switch (x.featureName) {
                                case FeatureName.CasinoManagement:
                                    this.SetCMSFeatureConfigurationFlags(config);
                                    break;
                                case FeatureName.ThirdPartGiftCard:
                                    this.SetGiftcardFeatureConfigurationFlags(config);
                                    break;
                                case FeatureName.ARAccountPosting:
                                    this.SetARAccountPostingConfigurationFlags(config);
                                    break;
                                case FeatureName.EnhancedInventory:
                                    this._isEatecEnabled = true;
                                    if (!this._propertyInfo.IsEatecAsMaster) {
                                        this.SetEatecFeatureConfigurationFlags(config);
                                        let propConfig = sessionStorage.getItem('propConfig') ? JSON.parse(sessionStorage.getItem('propConfig')) : null;
                                        this._isRetailIcEnabled = propConfig?.EnableRetailIC?.toLowerCase() == 'true' ? true : false;
                                    }
                                    break;
                                case FeatureName.PMS_RevenuePosting:
                                    this.SetRevenuePostingConfigurationFlags(config);
                                    break;
                                case FeatureName.OfferManagementSystem:
                                    this.SetOfferFeatureConfigurationFlags(config);
                                    break;
                                case FeatureName.COMP:
                                    this.SetCOMPFeatureConfigurationFlags(config);
                                    break;
                                case FeatureName.GroupCharge:
                                    this.SetGroupFeatureConfigurationFlags(config);
                                    break;
                                case FeatureName.ACES_Membership:
                                    this.SetACESFeatureConfigurationFlags(config);
                                    break;
                                case FeatureName.Lockers:
                                    this._isLockersEnabled = true;
                                    this.SetLockerConfigurationFlags(config);
                                    break
                                case FeatureName.Rentals:
                                    this._isRentalEnabled = true;
                                    break;
                                case FeatureName.DataExpress:
                                    this._isDataExpressEnabled = true;
                                    break;
                                default:
                                    break;
                            }
                        });
                        this.GetFeaturesCompleted.emit();
                    })
                } else {
                    this.GetFeaturesCompleted.emit();
                }
            });

            this.SetTerminalConfiguration();
            //Add your function call to set the feature flag
            this.GetGiftcardGatewayConfig().then((response) => {
                const config: GatewayConfiguration[] = response && response.result;
                this.SetGiftcardFlags(config);
            });
            this.GetCreditcardGatewayConfig().then((response) => {
                this.SetGatewayDeviceConfiguration(response.result);
            })

            this.GetNonIntegratedCreditCardConfiguration().then((response) => {
                const config: NonIntegratedCreditCardConfiguration = response && response.result;
                this.setNonIntegratedCreditCardConfiguration(config);

            })

            this._refreshConfigTriggered = true;
        }
        this.RefreshConfigTriggered.emit();
    }

    async SetRevenuePostingConfigurationFlags(featureconfigurations) {
        if (featureconfigurations) {
            const pmsSystem = this.ReadConfigHelper(featureconfigurations, ConfigKeys.PMSRevenuePosting.PMSSystem);
            const PostViaPMSCommunicationReceiver = this.ReadConfigHelper(featureconfigurations,ConfigKeys.PMSRevenuePosting.PostViaPMSCommunicationReceiver)?.toLowerCase();
            if (pmsSystem && pmsSystem != null) {
                this._pmsSystem = pmsSystem;
                sessionStorage.setItem("pmsSystem", pmsSystem);
            }
            if(PostViaPMSCommunicationReceiver && PostViaPMSCommunicationReceiver != null){
                this._IsPostViaPMSCommunicationReceiver = PostViaPMSCommunicationReceiver;
                sessionStorage.setItem("RevenuePosting_PostViaPMSCommunicationReceiver",PostViaPMSCommunicationReceiver);
            }else{
                sessionStorage.setItem("RevenuePosting_PostViaPMSCommunicationReceiver",'false');
            }
            this._skipPMS_XML = this.ReadConfigHelper(featureconfigurations, ConfigKeys.PMSRevenuePosting.SkipPMSXML, true, true) || false;
        }
    }

    /**
     * @description Updates the flags for CMS Feature
     */
    async SetCMSFeatureConfigurationFlags(featureconfigurations) {
        // By Default both void is allowed, until the key is set as false
        this._allowCompRedemptionVoid = true;
        this._allowOfferRedemptionVoid = true;
        this._allowOfferRedemption = true;
        this._offerRedeemAtCheckout = true;

        //Edit PatronId is disabled by default
        this._updateGuestInfoAsPerCMS = false;

        this._isCMSConfigured = false;
        if (featureconfigurations != null) {
            let siteId = featureconfigurations.find(f => f.configurationKey == ConfigKeys.CMS.SiteId);
            let uri = featureconfigurations.find(f => f.configurationKey == ConfigKeys.CMS.ServiceManagerURI);
            if (siteId && siteId.configurationValue && uri && uri.configurationValue) {
                this._isCMSConfigured = true;
                this._cmsSiteId = siteId.configurationValue;
            }
            let compVoidConfig = featureconfigurations.find(f => f.configurationKey == ConfigKeys.CMS.AllowCompRedemptionVoid);
            if (compVoidConfig != null && compVoidConfig.configurationValue.toLowerCase() == ConfigValue.false) {
                this._allowCompRedemptionVoid = false;
            }
            let offerVoidConfig = featureconfigurations.find(f => f.configurationKey == ConfigKeys.CMS.AllowOfferRedemptionVoid);
            if (offerVoidConfig != null && offerVoidConfig.configurationValue.toLowerCase() == ConfigValue.false) {
                this._allowOfferRedemptionVoid = false;
            }
            let offerRedempConfig = featureconfigurations.find(f => f.configurationKey == ConfigKeys.CMS.AllowOfferRedemption);
            if (offerRedempConfig != null && offerRedempConfig.configurationValue.toLowerCase() == ConfigValue.false) {
                this._allowOfferRedemption = false;
            }
            let offerRedeemAtCheckoutConfig = featureconfigurations.find(f => f.configurationKey == ConfigKeys.CMS.OfferRedeemAtCheckout);
            if (offerRedeemAtCheckoutConfig != null && offerRedeemAtCheckoutConfig.configurationValue.toLowerCase() == ConfigValue.false) {
                this._offerRedeemAtCheckout = false;
            }
            let guestUpdateConfig = featureconfigurations.find(f => f.configurationKey == ConfigKeys.CMS.UpdateGuestInfoAsPerCMS);
            if (guestUpdateConfig != null && guestUpdateConfig.configurationValue.toLowerCase() == ConfigValue.true) {
                this._updateGuestInfoAsPerCMS = true;
            }
            let cmsSearchTypeConfig = featureconfigurations.find(f => f.configurationKey == ConfigKeys.CMS.SearchType);
            if (cmsSearchTypeConfig != null && cmsSearchTypeConfig.configurationValue) {
                this._cmsSearchType = cmsSearchTypeConfig.configurationValue;
            }
            let allowAccrualPoints = featureconfigurations.find(f => f.configurationKey == ConfigKeys.CMS.AllowAccrualPoints);
            if (allowAccrualPoints && allowAccrualPoints.configurationValue.toLowerCase() == ConfigValue.true) {
                sessionStorage.setItem("allowAccrualPoints", ConfigValue.true);
            }
            else {
                sessionStorage.setItem("allowAccrualPoints", ConfigValue.false);
            }
            this._cmsProfitCenterId = this.ReadConfigHelper(featureconfigurations, ConfigKeys.CMS.ProfitCenterId, false, true) || 0;
            this._cmsProfitCenterName = this.ReadConfigHelper(featureconfigurations, ConfigKeys.CMS.ProfitCenterName, false, true) || "";
            this._cmsRequirePin = this.ReadConfigHelper(featureconfigurations, ConfigKeys.CMS.RequirePin, true, true) || false;
            this._cmsApplicableBuckets = this.ReadConfigHelper(featureconfigurations, ConfigKeys.CMS.ApplicableBuckets, false, true) || "";
            this._cmsCurrencyName = this.ReadConfigHelper(featureconfigurations, ConfigKeys.CMS.CurrencyName, false, true) || "";
            this._allowDuplicateDescription = this.ReadConfigHelper(featureconfigurations, ConfigKeys.CMS.DuplicateDescription, true, true) || false;
            this._multipleOfferSupport = this.ReadConfigHelper(featureconfigurations, ConfigKeys.CMS.AllowMultipleOfferSupport, true, true) || false;
        } else {
            this._isCMSConfigured = false;
            this._cmsSiteId = "";
            this._cmsProfitCenterId = 0;
            this._cmsProfitCenterName = "";
        }
    }

    SetOfferFeatureConfigurationFlags(featureconfigurations: FeaturesConfigurations[]) {
        if (featureconfigurations != null && this._isCMSConfigured && this._cmsSiteId) {
            this._omsOfferVoidConfig = [];
            featureconfigurations.filter(x => x.configurationKey.split(".")[2] == ConfigKeys.OfferManagementSystem.SiteId).forEach(f => {
                const offerVoidConfig: OMS_OfferVoidConfig = {
                    SiteId: f.configurationValue.toLowerCase(),
                    AllowOfferRedemptionVoid: true, //by default OfferRedemption Void is supported
                    OMSNumber: f.configurationKey.split(".")[1]
                }
                this._omsOfferVoidConfig.push(offerVoidConfig);
                if (f.configurationValue.toUpperCase() == "MGT") {
                    this._supportCompSlipRedemption = true;
                }
            });
            featureconfigurations.filter(x => x.configurationKey.split(".")[2] == ConfigKeys.OfferManagementSystem.AllowOfferRedemptionVoid).forEach(f => {
                let currentOMS = this._omsOfferVoidConfig.find(x => x.OMSNumber == f.configurationKey.split(".")[1]);
                if (currentOMS) { currentOMS.AllowOfferRedemptionVoid = (f.configurationValue.toLowerCase() != ConfigValue.false); }
            });
        }
        else {
            this._supportCompSlipRedemption = false;
        }
    }

    SetCOMPFeatureConfigurationFlags(featureconfigurations: FeaturesConfigurations[]) {
        this._isCOMPConfigured = true;
    }

    SetGroupFeatureConfigurationFlags(featureconfigurations: FeaturesConfigurations[]) {
        this._groupChargePMSSystem = this.ReadConfigHelper(featureconfigurations, ConfigKeys.GroupCharge.PMSSystem);
        let groupHideSearchByGroupName = this.ReadConfigHelper(featureconfigurations, ConfigKeys.GroupCharge.HideSearchByGroupName, true);
        if (groupHideSearchByGroupName)
            this._groupHideSearchByGroupName = true;
    }

    setNonIntegratedCreditCardConfiguration(nonIntegratedConfig: NonIntegratedCreditCardConfiguration) {
        if (nonIntegratedConfig.activateInterface) {
            sessionStorage.setItem("nonIntegratedCreditCard", ConfigValue.true);
        }
    }

    /**
     * @description Returns the value of the key specified from the config list
     * @param featureconfigurations
     * @param key
     * @param isBoolean
     */
    private ReadConfigHelper(featureconfigurations, key, isBoolean = false, ignoreCase = true) {
        let keyObj = featureconfigurations.find(f => f.configurationKey?.trim() == key);
        if (ignoreCase) { keyObj = featureconfigurations.find(f => f.configurationKey?.trim()?.toLowerCase() == key.toLowerCase()); }
        if (keyObj && keyObj.configurationValue) {
            if (!isBoolean) {
                return keyObj.configurationValue.trim();
            } else {
                return (keyObj.configurationValue.trim().toLowerCase() == ConfigValue.true)
            }
        }
    }

    //3rd Party Gift Card
    /**
     * @description Updates the flags for Gift card Feature
     */
    async SetGiftcardFeatureConfigurationFlags(featureconfigurations) {

        this._zoneOffset = this._gatewayType = "";
        if (featureconfigurations != null) {
            this._zoneOffset = this.ReadConfigHelper(featureconfigurations, ConfigKeys.GiftCard.ZoneOffset);
            this._gatewayType = this.ReadConfigHelper(featureconfigurations, ConfigKeys.GiftCard.GatewayType);
            this._allowPinValidation = this.ReadConfigHelper(featureconfigurations, ConfigKeys.GiftCard.AllowPinValidation, true);
            this._supportLoadReversal = this.ReadConfigHelper(featureconfigurations, ConfigKeys.GiftCard.SupportLoadReversal, true);
            this._supportIssueReversal = this.ReadConfigHelper(featureconfigurations, ConfigKeys.GiftCard.SupportIssueReversal, true);
            this._cardDataSource = this.ReadConfigHelper(featureconfigurations, ConfigKeys.GiftCard.CardDataSource) ?? "";
            this._cardDataRegex = this.ReadConfigHelper(featureconfigurations, ConfigKeys.GiftCard.CardDataRegex) ?? "";
            this._giftcardIssueanceSupportedByVendor = this.ReadConfigHelper(featureconfigurations, ConfigKeys.GiftCard.GiftcardIssuanceSupportedByVendor, true);
            this._initiateLoadForCardActivation = this.ReadConfigHelper(featureconfigurations, ConfigKeys.GiftCard.InitiateLoadForCardActivation, true);
            this._errorMessageForInactiveGiftCards = this.ReadConfigHelper(featureconfigurations, ConfigKeys.GiftCard.ErrorMessageForInactiveGiftCards) ?? "";
            this._validateCardNumber = this.ReadConfigHelper(featureconfigurations, ConfigKeys.GiftCard.ValidateCardNumber, true);
        }
    }
    SetLockerConfigurationFlags(featureconfigurations: FeaturesConfigurations[]) {
        const IncludeLockerAssignment = this.ReadConfigHelper(featureconfigurations, ConfigKeys.Lockers.IncludeLockerAssignment,true)
        if (IncludeLockerAssignment != null) {
            this._includeLockerAssignment = IncludeLockerAssignment;
        }
    }
    SetACESFeatureConfigurationFlags(featureconfigurations: FeaturesConfigurations[]) {
        if (featureconfigurations != null && featureconfigurations.length > 0) {
            const memberDiscountComment = this._memberDiscountComment = this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.MemberDiscountComment) ?? "";
            const memberDiscountReason = this._memberDiscountReason = this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.MemberDiscountReason) ?? "";
            const applicableCorporates = this._applicableCorporates = this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.ApplicableCorporates) ?? "";
            const corpIdNameMap = this._corpIdNameMap = this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.CorpIdNameMap) ?? "";
            const loyaltySourceCorpID = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.LoyaltySourceCorpID);

            const loyalty = {
                loyaltyURI: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.LoyaltyURI) ?? "",
                loyaltyServiceURI: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.LoyaltyServiceURI) ?? "",
                loyaltyTokenRequestInfo: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.LoyaltyTokenRequestInfo) ?? "",
                loyaltySourceCorpID: loyaltySourceCorpID && loyaltySourceCorpID.configurationValue ? loyaltySourceCorpID.configurationValue : 0,
                enforceMemberPayment: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.EnforceMemberPayment) || "false",
                displayCreditBookBalance: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.DisplayCreditBookBalance) || "false",
                allowTenderAmountOverrideForMember: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.AllowTenderAmountOverrideForMember) || "false",
                memberBucketName: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.MemberBucketName) || DefaultBucketName.memberBucketName,
                creditBookBucketName: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.CreditBookBucketName) || DefaultBucketName.creditBookBucketName,
                requirePin: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.RequirePin) || "false",
                redeemRoundsForGuests: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.RedeemRoundsForGuests) || 'false',
                enableMultipleMember: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.EnableMultipleMember) || 'false',
                showCurrentRoundsOnReprint: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.ShowCurrentRoundsOnReprint) || 'false',
                applyMemberDiscountAutomatically: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.ApplyMemberDiscountAutomatically) || 'false',
                overrideWithMemberDiscounts: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.OverrideWithMemberDiscounts) || 'false',
                accrualProvider: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.AccrualProvider) ?? "",
                accrualProviderUrl: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.AccrualProviderUrl) ?? "",
                allowMemberPartialPayment: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.AllowMemberPartialPayment) || 'false',
                memberDiscountComment: memberDiscountComment,
                memberDiscountReason: memberDiscountReason,
                applicableCorporates: applicableCorporates,
                corpIdNameMap: corpIdNameMap,
                isExternalMembershipEnabled: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.IsExternalMembershipEnabled) || 'false',
                externalMembershipInterfaceName: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.ExternalMembershipInterfaceName) ?? "",
                showARAccount: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.ShowARAccount) || 'true',
                defaultMultiMembertoSingle: this.ReadConfigHelper(featureconfigurations, ConfigKeys.Member.DefaultMultiMembertoSingle) || 'false',
            }
            this._propertyInfo.SetMemberConfiguration(loyalty);
        } else {
            this._propertyInfo.SetMemberConfiguration("");
        }
    }

    async SetEatecFeatureConfigurationFlags(featureconfigurations) {
        if (featureconfigurations != null && featureconfigurations.length > 0) {
            const eatecUser = featureconfigurations.find(f => f.configurationKey == ConfigKeys.Eatec.EatecTenantUser);
            const uri = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Eatec.EatecURI);
            const EIFeature = {
                EISSOWaitPeriodInSecs: featureconfigurations.find(f => f.configurationKey === ConfigKeys.Eatec.EISSOWaitPeriodInSecs)?.configurationValue,
                EISSOTriggerAlways: featureconfigurations.find(f => f.configurationKey === ConfigKeys.Eatec.EISSOTriggerAlways)?.configurationValue
            };
            this._propertyInfo.SetEatecConfiguration(EIFeature);
            if (eatecUser && eatecUser.configurationValue && uri && uri.configurationValue) {
                this._isEatecConfigured = true;
                this._propertyInfo.SetEatecRI(uri.configurationValue);
            } else {
                this._propertyInfo.SetEatecRI('');
            }
        } else {
            this._isEatecConfigured = false;
            this._propertyInfo.SetEatecRI('');
        }
    }

    async SetARAccountPostingConfigurationFlags(featureconfigurations) {
        if (featureconfigurations != null) {
            const uri = featureconfigurations.find(f => f.configurationKey === ConfigKeys.ARAccountPosting.ARAccountPostingServiceURI);
            if (uri && uri.configurationValue) {
                this._isARAccountPostingConfigured = true;
            }
            const profitCenter = featureconfigurations.find(f => f.configurationKey === ConfigKeys.ARAccountPosting.ProfitCenter);
            if (profitCenter && profitCenter.configurationValue) {
                this._profitCenter = profitCenter.configurationValue;
            }
            const senderCode = featureconfigurations.find(f => f.configurationKey === ConfigKeys.ARAccountPosting.SenderCode);
            if (senderCode && senderCode.configurationValue) {
                this._SenderCode = senderCode.configurationValue;
            }
            const prepopulateMemberAR = featureconfigurations.find(f => f.configurationKey === ConfigKeys.ARAccountPosting.PrepopulateMemberAR);
            if (prepopulateMemberAR && prepopulateMemberAR.configurationValue) {
                this._PrePopulateMemberAR = prepopulateMemberAR.configurationValue;
            }
        } else {
            this._isARAccountPostingConfigured = false;
        }
    }

    async GetFeatureConfigurations(featureNames: PropertyFeature[]): Promise<FeaturesConfigurations[]> {
        let propertyId = Number(this._localization.GetPropertyInfo("PropertyId"));
        let productId = Number(this._localization.GetPropertyInfo("ProductId"));
        if (propertyId && productId) {
            let response: Promise<BaseResponse<any>>[] = [];
            if (featureNames.filter(x => x.moduleId == GlobalConst.Modules.PAYMENT).length)
                response.push(this.http.CallApiAsync<any>({
                    host: GlobalConst.Host.payment,
                    callDesc: "GetFeatureConfiguration",
                    method: HttpMethod.Put,
                    uriParams: { propertyId: propertyId, productId: productId },
                    body: featureNames.map(f => f.featureName)
                }));
            if (featureNames.filter(x => x.moduleId == GlobalConst.Modules.RETAILMGT).length)
                response.push(this.http.CallApiAsync<any>({
                    host: GlobalConst.Host.retailManagement,
                    callDesc: "GetRetailFeatureConfiguration",
                    method: HttpMethod.Put,
                    uriParams: { propertyId: propertyId, productId: productId },
                    body: featureNames.map(f => f.featureName)
                }));
            const configs = (await Promise.all(response)).map(x => x && x.result) as any;
            return configs.flat()
        }
    }

    async GetPropertyFeatures(): Promise<PropertyFeature[]> {
        let propertyId = Number(this._localization.GetPropertyInfo("PropertyId"));
        if (propertyId) {
            let response: any = await this.http.CallApiAsync<any>({
                host: GlobalConst.Host.authentication,
                callDesc: "GetPropertyFeatures",
                method: HttpMethod.Get,
                uriParams: { propertyId: propertyId, productId: GlobalConst.Product.RETAIL },
                body: undefined
            });
            return response.result;
        }
    }

    private async GetGiftcardGatewayConfig() {
        try {
            return await this.http.CallApiAsync<any>({
                host: GlobalConst.Host.payment,
                callDesc: "GetCCConfiguration",
                method: HttpMethod.Get,
                uriParams: { type: GlobalConst.GiftCardType.Both }
            });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    private async GetCreditcardGatewayConfig() {
        try {
            return await this.http.CallApiAsync<any>({
                host: GlobalConst.Host.payment,
                callDesc: "GetCCConfiguration",
                method: HttpMethod.Get,
                uriParams: { type: GlobalConst.GatewayConfigurationType.CreditCard }
            });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    private async GetNonIntegratedCreditCardConfiguration() {
        try {
            return await this.http.CallApiAsync<any>({
                host: GlobalConst.Host.payment,
                callDesc: RetailRoutes.GetNonIntegratedCreditCardConfiguration,
                method: HttpMethod.Get
            });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async GetMiscConfigurationSetting() {
        try {
            return await this.http.CallApiAsync<any>({
                host: GlobalConst.Host.retailManagement,
                callDesc: "GetMiscConfiguration",
                method: HttpMethod.Get
            });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public async GetMiscConfig() {
        const miscSettings = await this.GetMiscConfigurationSetting();
        this._isAllowSurcharge = miscSettings.result.find(x => x.switch == MiscellaneousSwitch.ALLOW_PAYMENT_SURCHARGE).value == "true" ? true : false;
        return this._isAllowSurcharge;
    }

    ValidateOfferRedemptionVoid(vendorName: string) {
        vendorName = vendorName && vendorName.toLowerCase();
        let _allowOfferRedemptionVoid = true; //by default offer redemption void will be supported
        if ((vendorName && this._omsOfferVoidConfig.length > 0 && this._omsOfferVoidConfig.some(x => x.SiteId.toLowerCase() == vendorName))
            || (vendorName && this._cmsSiteId)) {
            const currentConfig = this._omsOfferVoidConfig.find(x => x.SiteId.toLowerCase() == vendorName);
            _allowOfferRedemptionVoid = (vendorName.toLowerCase() == this._cmsSiteId.toLowerCase()) ? this._allowOfferRedemptionVoid
                : currentConfig && currentConfig.AllowOfferRedemptionVoid;
        }
        return _allowOfferRedemptionVoid;
    }

    SetGiftcardFlags(config: GatewayConfiguration[]) {
        if (config && config.length > 0) {
            const v1GiftCardConfig = config.find(x => x.type == GlobalConst.GiftCardType.V1GiftCard);
            const externalGiftCardConfig = config.find(x => x.type == GlobalConst.GiftCardType.ExternalGiftCard);
            if (v1GiftCardConfig) {
                this._v1GiftcardRGuestPayURL = v1GiftCardConfig?.payAgentURL;
                sessionStorage.setItem("v1GiftcardRGuestPayURL", this._v1GiftcardRGuestPayURL);
            }
            const v1GCLimit = v1GiftCardConfig?.gatewayValues.find(x => x.configKey == ConfigKeys.GiftCard.GiftcardMaxLimit)?.configValue;
            const externalGCLimit = externalGiftCardConfig?.gatewayValues.find(x => x.configKey == ConfigKeys.GiftCard.GiftcardMaxLimit)?.configValue;
            this._v1GiftcardMaxLimit = !isNaN(Number(v1GCLimit)) ? Number(v1GCLimit) : this.DEFAULT_MAX_CARDS_PER_TRANS;
            this._externalGiftcardMaxLimit = !isNaN(Number(externalGCLimit)) ? Number(externalGCLimit) : this.DEFAULT_MAX_CARDS_PER_TRANS;
        }
    }
    async SetGatewayDeviceConfiguration(config: GatewayConfiguration) {
        let detectDeviceOnLogin = config[0]?.gatewayValues?.find(x => x.configKey.toLowerCase().trim() == ConfigKeys.GatewayDeviceConfiguration.DetectDeviceOnLogin.toLowerCase())?.configValue.toLowerCase().trim() == 'true' ? true : false;
        let outletId = config[0]?.gatewayValues?.find(x => x.configKey.toLowerCase().trim() == ConfigKeys.GatewayDeviceConfiguration.OutletIdForDeviceDetection.toLowerCase())?.configValue.trim() ?? 0;
        if (detectDeviceOnLogin) {
            if (this.$scope) {
                const handleRequest: HandleRequest = { tenderId: PaymentMethods.CreditCard.toString() };
                const Handles: Promise<HandleResponse> = this.$scope?.GetHandles(handleRequest, outletId, null, false);
                Handles.then(response => {
                    this.$scope = null;
                    if (response && response.status.toLocaleLowerCase() === 'success' && response.paymentHandle.length > 0) {
                        this._gatewayDeviceConfiguration = [];
                        const gatewayDeviceConfig: GatewayDeviceConfigurationStore = {
                            DetectDeviceOnLogin: detectDeviceOnLogin,
                            OutletIdForDeviceDetection: Number(outletId)
                        }
                        this._gatewayDeviceConfiguration.push(gatewayDeviceConfig);
                        sessionStorage.setItem(GatewayDeviceConfiguration, JSON.stringify(this._gatewayDeviceConfiguration));
                        let DeviceGuID = response.paymentHandle[0]?.deviceId;
                        let DeviceName = response.paymentHandle[0]?.name;
                        let deviceSessionDetails: DeviceDetails = {
                            deviceId: DeviceGuID,
                            deviceName: DeviceName
                        };
                        //this._gatewayDeviceConfiguration.push(DeviceGuID);
                        //sessionStorage.setItem(GatewayDeviceConfiguration, JSON.stringify(this._gatewayDeviceConfiguration));
                        this.retailCommunication.postPromise<boolean>({ route: RetailRoutes.AddDeviceIdToSesssion, body: deviceSessionDetails });
                    }


                }).catch(err => {
                    // Show Payment Error Prompt
                    if (err.error && (err.error[0] != null && err.error[0].Code != null && err.error[0].Code != '')) {
                        this._utils.PaymentErrorPrompt(err.error[0].Code);
                    }
                    else {
                        //return this._utils.showError(this._localization.captions.shop.PayAgentUnavailable);
                    }
                });
            }
        } 
    }

    async SetTerminalConfiguration() {
        try {
            // Storing Terminal Details in session
            if (this.SkipPMAgent) {
                const terminalDetails = await this.$scope?.GetTerminalDetails();
                if (terminalDetails) {
                    sessionStorage.setItem('terminalDetails', JSON.stringify(terminalDetails));
                }
            }
        } catch (error) {
            console.error('Error setting terminal configuration:', error);
        }
    }

    IsSkipPMAgent(paymentMethod: PaymentMethod): boolean {
        let isSkipPMAgent: boolean = false;

        //Property Level SkipPMAgent.
        const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
        const IsPropertyLevelSkipPMAgent = propConfig?.SkipPMAgent ?? '';

        if (paymentMethod && paymentMethod?.additionalConfigurations) {
            //Payment Method Level SkipPMAgent.
            const paymentMethodAdditionalConfig = JSON.parse(paymentMethod?.additionalConfigurations);
            const payMethodConfig = paymentMethodAdditionalConfig?.find((x) => x.Key.toLowerCase().trim() === 'skippmagent');
            const IsPayMethodLevelSkipPMAgent = payMethodConfig?.Value ?? '';
            //To Handle UnSkip At Payment Method Level.
            if (IsPayMethodLevelSkipPMAgent.trim().toLowerCase() == 'false') {
                isSkipPMAgent = false;
            }
            else {
                isSkipPMAgent = (IsPropertyLevelSkipPMAgent.trim().toLowerCase() == 'true' || IsPayMethodLevelSkipPMAgent.trim().toLowerCase() == 'true');
            }
        }
        else {
            isSkipPMAgent = (IsPropertyLevelSkipPMAgent.trim().toLowerCase() == 'true');
        }
        return isSkipPMAgent;
    }
}

export const ConfigKeys = {
    CMS: {
        AllowCompRedemptionVoid: "AllowCompRedemptionVoid",
        AllowOfferRedemptionVoid: "AllowOfferRedemptionVoid",
        AllowOfferRedemption: "AllowOfferRedemption",
        UpdateGuestInfoAsPerCMS: "UpdateGuestInfoAsPerCMS",
        SiteId: "SiteId",
        ServiceManagerURI: "ServiceManagerURI",
        SearchType: "SearchType",
        AllowAccrualPoints: "AllowAccrualPoints",
        ProfitCenterId: "ProfitCenterId",
        ProfitCenterName: "ProfitCenterName",
        OfferRedeemAtCheckout: "OfferRedeemAtCheckout",
        RequirePin: "RequirePin",
        ApplicableBuckets: "ApplicableBuckets",
        CurrencyName: "CurrencyName",
        DuplicateDescription: "DuplicateDescription",
        AllowMultipleOfferSupport: "AllowMultipleOfferSupport"
    },
    GiftCard: {
        GatewayType: "rGuestGatewayType",
        ZoneOffset: "ZoneOffset",
        AllowPinValidation: "AllowPINValidation",
        SupportLoadReversal: "SupportLoadReversal",
        SupportIssueReversal: "SupportIssueReversal",
        GiftcardMaxLimit: "CardsPerTransactionLimit",
        CardDataSource: "CardDataSource",
        CardDataRegex: "CardDataRegex",
        GiftcardIssuanceSupportedByVendor: "GiftcardIssuanceSupportedByVendor",
        InitiateLoadForCardActivation: "InitiateLoadForCardActivation",
        ErrorMessageForInactiveGiftCards: "ErrorMessageForInactiveGiftCards",
        ValidateCardNumber: "ValidateCardNumber"
    },
    Eatec: {
        EatecInventoryServiceURI: "EIInventoryServiceURI",
        EatecTenantUser: "EITenantUser",
        EatecURI: "EIURI",
        EISSOWaitPeriodInSecs: "EISSOWaitPeriodInSecs",
        EISSOTriggerAlways: "EISSOTriggerAlways"
    },
    ARAccountPosting: {
        ARAccountPostingServiceURI: 'ARAccountPostingServiceURI',
        ProfitCenter: "ProfitCenter",
        SenderCode: "SenderCode",
        PrepopulateMemberAR: "PrepopulateMemberAR"
    },
    Member: {
        LoyaltyURI: "LoyaltyURI",
        LoyaltyServiceURI: "LoyaltyServiceURI",
        LoyaltyTokenRequestInfo: "LoyaltyTokenRequestInfo",
        LoyaltySourceCorpID: "LoyaltySourceCorpID",
        EnforceMemberPayment: "EnforceMemberPayment",
        DisplayCreditBookBalance: "DisplayCreditBookBalance",
        AllowTenderAmountOverrideForMember: "AllowTenderAmountOverrideForMember",
        MemberBucketName: "MemberBucketName",
        CreditBookBucketName: "CreditBookBucketName",
        RequirePin: "RequirePin",
        RedeemRoundsForGuests: 'RedeemRoundsForGuests',
        EnableMultipleMember: "EnableMultipleMember",
        ShowCurrentRoundsOnReprint: "ShowCurrentRoundsOnReprint",
        MemberDiscountComment: "MemberDiscountComment",
        MemberDiscountReason: "MemberDiscountReason",
        ApplicableCorporates: "ApplicableCorporates",
        CorpIdNameMap: "CorpIdNameMap",
        ApplyMemberDiscountAutomatically: "ApplyMemberDiscountAutomatically",
        OverrideWithMemberDiscounts: "OverrideWithMemberDiscounts",
        AccrualProvider: "AccrualProvider",
        AccrualProviderUrl: "AccrualProviderUrl",
        AllowMemberPartialPayment: "AllowMemberPartialPayment",
        IsExternalMembershipEnabled: "IsExternalMembershipEnabled",
        ExternalMembershipInterfaceName: "ExternalMembershipInterfaceName",
        ShowARAccount: "ShowARAccount",
        DefaultMultiMembertoSingle: "DefaultMultiMembertoSingle"
    },
    PMSRevenuePosting: {
        PMSSystem: "PMSSystem",
        SkipPMSXML: "SkipPMSXML",
        PostViaPMSCommunicationReceiver : "PostViaPMSCommunicationReceiver"
    },
    OfferManagementSystem: {
        AllowOfferRedemptionVoid: "AllowOfferRedemptionVoid",
        SiteId: "SiteId",
        ServiceManagerURI: "ServiceManagerURI",
        SearchType: "SearchType"
    },
    GroupCharge: {
        PMSSystem: "PMSSystem",
        HideSearchByGroupName: "HideSearchByGroupName"
    },
    GatewayDeviceConfiguration: {
        DetectDeviceOnLogin: "DetectDeviceOnLogin",
        OutletIdForDeviceDetection: "OutletIdForDeviceDetection",
        InvokeRguestpayViaProxy: "InvokeRguestpayViaProxy"
    },
    Lockers: {
        IncludeLockerAssignment: 'IncludeLockerAssignment'
    }

};

export const FeatureName = {
    EnhancedInventory: "Enhanced Inventory",
    CasinoManagement: "CMS",
    ThirdPartGiftCard: "External Gift Card",
    ARAccountPosting: "ARAccountPosting",
    ACES_Membership: "ACES Membership",
    PMS_RevenuePosting: "PMS Revenue Posting",
    OfferManagementSystem: "Offer Management System",
    COMP: "COMP",
    GroupCharge: "Group Charge",
    MultiPMSRoomGroupCharge: "Multiple Properties",
    Lockers: "Lockers",
    Rentals: "Rentals",
    DataExpress: "Data Express"
}

export const ConfigValue = {
    true: "true",
    false: "false"
};

export interface OMS_OfferVoidConfig {
    SiteId: string;
    OMSNumber: string;
    AllowOfferRedemptionVoid: boolean;
}

export type PmsSystemType = 'V1' | 'STAY' | 'LMS' | 'COMTROL' | 'RESORTSUITE' | 'OPERA' | 'INFOR' | 'LIGHTSPEED' | 'FOSSE' | 'FSPMS' | 'VisualOne' | '';

export enum PMS_SYSTEM {
    V1 = "V1",
    STAY = "STAY",
    LMS = "LMS",
    COMTROL = "COMTROL",
    RESORT_SUITE = "RESORTSUITE",
    OPERA = "OPERA",
    INFOR = "INFOR",
    LIGHT_SPEED = "LIGHTSPEED",
    FOSSE = "FOSSE",
    FSPMS = "FSPMS",
    VISUALONE = "VisualOne"
}

export enum PMS_SYSTEM_NAMES {
    V1 = "V1",
    STAY = "Stay",
    LMS = "LMS",
    COMTROL = "COMTROL",
    RESORT_SUITE = "ResortSuite",
    OPERA = "Opera",
    OPERA_HTNG = "OPERAHTNG",
    OPERA_REST = "OPERAREST",
    INFOR = "Infor",
    LIGHT_SPEED = "LightSpeed",
    FOSSE = "FOSSE",
    FSPMS = "FSPMS",
    VisualOne = "VisualOne"
}

export const MultiPropertyDefaultToggle = {
    DefaultRoomCharge: "defaultRoomCharge",
    DefaultGroupCharge: "defaultGroupCharge"
}

export const RetailConstants = {
    EnableResortFinance: "enableResortFinance",
    BooleanTrue: "true",
    BooleanFalse: "false"
}

export enum SearchTypes {
    None = 0,
    GroupCode,
    GroupName,
    BookingId
}