import { SpaServiceLocation, TherapistDetails, PackageDetail, SpaServices, ServiceAddOns, LinkCode, SystemConfiguration, BreakType, PackageAvailableDays, PackageYield } from "./view-settings.modals";
import { GridOperationType, noRecordsType } from "../globalsContant";
import { ACESPaymentRecord, ARPostPaymentResponse, CMSPaymentDetails, GiftCardPaymentRecord, PayAgentResponse } from "src/app/common/Models/payment.model";


export interface MoveReponse {
  errorList: number[],
  unCertifiedAddOns: number[]
}

export interface BaseResponse<T> {
  result: T;
  errorCode: number;
  errorDescription: string;
  successStatus: boolean;
  propertyId: number;
  outletId: number;
}


export interface googleApi {
  results: Result[];
  status: string;
}

export interface Result {
  address_components: Addresscomponent[];
  formatted_address: string;
  geometry: Geometry;
  place_id: string;
  types: string[];
}

export interface Geometry {
  bounds: Bounds;
  location: Northeast;
  location_type: string;
  viewport: Bounds;
}

export interface Bounds {
  northeast: Northeast;
  southwest: Northeast;
}

export interface Northeast {
  lat: number;
  lng: number;
}

export interface Addresscomponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface ClientScreenProperties {
  minGuest: number;
  maxGuest: number;
  helperText: string;
  selectedStaff?: any;
}

export interface multiClientInfo {
  id: number;
  comments: string;
  TherapistId: number;
  clientName?: string;
  priceTypeId: number;
  price: number;
}

export interface Location {
  id?: number;
  code: string;
  description: string;
  listOrder: number;
}

export interface appointment {
  appointmentDetail: appointmentDetail;
  appointmentTherapists: appointmentTherapist[];
  appointmentAddOn: appointmentAddOn[];
}

export interface appointmentDetail {
  id?: any;
  startTime: any;
  endTime: any;
  clientId: number;
  serviceGroupId: number;
  serviceId: number;
  locationId: number;
  comments: string;
  status: string,
  cancelId: any;
  duration: any;
  setUpTime: any;
  breakDownTime: any;
  price: any;
  doNotMove: boolean;
  requestStaff: boolean;
  isVip: boolean;
  genderPreference: any;
  checkOutComments: string;
  noShow: boolean;
  packageId: number;
  appointmentType: string;
  cancelReason: number;
  cancelComments: string;
  cancelFee: any;
  customField1?: number;
  customField2?: number;
  customField3?: number;
  customField4: string;
  customField5: string;
  tempHoldId: string;
  tempHoldLinkId: number;
  isTherapistOverbook: boolean;
  isLocationOverbook: boolean;
  isClientOverbook?: boolean;
  linkCodeId?: number;
  guestId?: string;
  clientComments: string;
  activityId?: any;
  clientMultiPackId?: any;
  transactionId?: number;
  transactionDetailId?: number;
  ServiceCharge?: number;
  Gratuity?: number;
  Tax?: number;
  TotalAmount?: number;
  multiGroupId?: string;
  userSessionId?: number;
  packageGroupId?: string;
  priceTypeId?: number;
  serviceChargePercent? : number;
  gratuityPercent?: number;
  yieldPrice?: number;
}
export interface appointmentTherapist {
  therapistId: number;
  appointmentId: number;
  id?: number
}

export interface appointmentAddOn {
  id?: number;
  appointmentId: number;
  addOnId: number;
  therapistId: number;
  price: number;
}

export interface AppointmentCancel {
  id: number;
  cancelReason: number;
  cancelFee: any;
  cancelComments: string;
  depositRefundAmount : number;
}

export interface AppointmentAction {
  id: number;
  clientId: number;
  serviceId: number;
  serviceGroupId: number;
  locationId: number;
  startTime: string;
  therapistId: any;
  servicePrice?: any;
  tempIds?: any;
  isTherapistOverbook?: boolean;
  isLocationOverbook?: boolean;
  isClientOverbook?: boolean;
  clientMultiPackId?: number;
  transactionid?: number;
  transactionDetailId?: number;
  ServiceCharge?: number;
  Gratuity?: number;
  Tax?: number;
  TotalAmount?: number;
  duration?: number;
  breakdownTime?: number;
  setupTime?: number;
  MoveTherapist?: MoveTherapist;
  AddOns?: appointmentAddOn[];
  packageGroupId?: string;
  multiGroupId?: string;
  priceTypeId?: number;
  serviceChargePercent : number;
  gratuityPercent: number;
  yieldPrice?: number;
}

export interface MoveTherapist {
  ApplyAddOnRule: boolean;
  FromTherapistId: number;
  ToTherapistId: number;
}

export interface AppointmentCheckoutWithTransaction {
  appointmentId: number;
  transactionId: number;
  transactionDetailId: number;
  serviceCharge: number;
  gratuity: number;
  tax: number;
}

export interface AppointmentLineNumber {
  appointmentId: number;
  LineNumber: number;
}

export interface ClientInfo {
  id: number;
  clientDetail: ClientDetail;
  phoneNumbers: PhoneNumber[];
  addresses: Address[];
  emails: Email[];
  clientMedicalCondition: MedicalHistory[];
  clientPreferences: ClientPreference[];
  clientSOAPNotes: ClientSOAPNote[];
  clientCreditCardInfo: ClientCreditCardInfo[];
}

export interface Email {
  id: number;
  contactTypeId: number;
  clientId: number;
  emailId: string;
  isPrivate: boolean;
  isPrimary: boolean;
  propertyId: number;
  subPropertyId: number;
}

export interface PhoneNumber {
  id: number;
  contactTypeId: number;
  clientId: number;
  countryCode: string | number;
  number: string;
  extension: string;
  isPrivate: boolean;
  isPrimary: boolean;
  propertyId: number;
  subPropertyId: number;
}

export interface ClientDetail {
  id: number;
  guestId?: string,
  title: string;
  firstName: string;
  FirstName?: string;
  lastName?: string;
  LastName?: string;
  pronounce: string;
  gender: string;
  dateOfBirth: string;
  comments: string;
  bypassClientScheduling: boolean;
  propertyId: number;
  subPropertyId: number;
  customField1: any;
  customField2: any;
  customField3: any;
  customField4: any;
  customField5: any;
  genderPreference: string;
  lastChangeId?: string;
  interfaceGuestId: string;
  priceTypeId: number;
  loyaltyDetail: LoyaltyDetail[];
}

export interface LoyaltyDetail{
  patronId: string;
  rank: string;
}

export interface Address {
  clientId: number;
  contactTypeId: number;
  line1?: string;
  Line1?: string;
  line2: string;
  line3: string;
  state?: string;
  State?: string;
  city: string;
  country?: string;
  Country?: string;
  zip: string;
  isPrivate: boolean;
  id: number;
}

export interface MedicalHistory {
  clientId: number;
  medicalConditionId: number;
}

export interface BaseResp {
  result: any;
  errorCode: number;
  errorDescription?: any;
  successStatus: boolean;
  propertyId: number;
  outletId: number;
}
export interface TempHoldApp {
  startTime: any;
  endTime: any;
  serviceGroupId: number;
  clientId: any;
  service: number;
  location: number;
  comments: string;
  status: any;
  cancelId: string;
  duration: any;
  setUpTime: any;
  breakDownTime: any;
  price: any;
  doNotMove: any;
  requestStaff: boolean;
  isVip: boolean;
  genderPreference: number;
  checkOutComments: string;
  noShow: boolean;
  packageId: number;
  therapist: any[];
  appointmentId: number;
  appointmentDate: any;
  TempHoldId: string;
  TempHoldLinkId: number;
  originalClientId: number;
  multiGroupId: number;
  transactionid?: number;
  transactionDetailId?: number;
  ServiceCharge?: number;
  Gratuity?: number;
  Tax?: number;
  TotalAmount?: number;
}

export interface AppointmentSearchData {
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  clientName: string
  clientId: number
  serviceName: string
  serviceId: number
  packageName: string
  packageId: number
  therapistName: string
  therapistId: number
  locationName: string
  locationId: number
  status: string
  appointmentId: number
  cancelId: string
  retailTransactionNo: string
  confirmationNo: string
  appointmentTime: string
}
export interface staffSchedule {
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  therapistName: string
  therapistId: number
  breaktype: string
  breakduration: string
  therapist: string
  comments: string;
}
export interface SearchByValues {
  filterValue: string;
  filterId: number;
  filterType: string;
}


export interface AppointmentUpdate {
  tempHoldId: any;
  appointments: appointment[];
}



export interface IdentityModel_Name {
  id: number;
  name: string;
}

export interface DaysLocaleSorted_app_multiple {
  id?: number,
  name: string;
  setSelected: boolean;
}


export interface Calendar {
  Sun: string;
  Mon: string;
  Tue: string;
  Wed: string;
  Thu: string;
  Fri: string;
  Sat: string;
  Sunday: string;
  Monday: string;
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  Saturday: string;
  January: string;
  February: string;
  March: string;
  April: string;
  May: string;
  June: string;
  July: string;
  August: string;
  September: string;
  October: string;
  November: string;
  December: string;
  Jan: string;
  Feb: string;
  Mar: string;
  Apr: string;
  Jun: string;
  Jul: string;
  Aug: string;
  Sep: string;
  Oct: string;
  Nov: string;
  Dec: string;
}

export interface DaysModel {
  id: number;
  short: string;
  long: string;
  code: string;
}

export interface ClientPreference {
  id: number;
  ClientId: number;
  TypeName: string;
  TypeId: number;
}

export interface ClientSOAPNote {
  id: number;
  clientId: number;
  userId: number;
  userName: string;
  note: string;
  loggedTime: string;
}

export interface LocalizedMonthsModel {
  id: number;
  short: string;
  long: string;
  code: string;
}

export interface Deposit {
  id: number;
  AppointmentId: number;
  Amount: any;
  Gratuity: number;
  ServiceCharge: number;
}

export interface DepositAPIModel{
  id: number;
  appointmentId: number;
  amount: number;
  gratuity: number;
  serviceCharge: number;
  refundAmount : number;
  depositTransactionId: number;
  refundTransactionId: number;
  isVoided : boolean;
}


export interface ShopItemDetails {
  id: number
  externalPOSId: number
  propertyId: number
  subPropertyId: number
  itemNumber: number
  itemName: string
  itemDescription: string
  salesPrice: number
  ProductPrice: number
  memberPrice: number
  suggestedPrice: number
  costPrice: number
  marginPercentage: number
  barcode: string
  secondaryBarcode: string
  category: number
  isCommissionable: boolean
  isCommissionRequired: boolean
  isSalesTax: boolean
  isBevTax: boolean
  itemType: number
  isGroupingKey: boolean
  isScaledItem: boolean
  uom: string
  isTeeTimeTrigger: boolean
  isCartTrigger: boolean
  isActive: boolean
  isRequestName: boolean
  isPMSPackageItem: boolean
  isRequireComments: boolean
  isOpenItem: boolean
  isMultiPack: boolean
  isGratuityAllowed: boolean
  gratuity: number
  isServiceChargeAllowed: boolean
  serviceCharge: number
  isPrintCartAgreement: boolean
  isPrintClubAgreement: boolean
  isPrintShoeAgreement: boolean
  isPrintToCaddyShack: boolean
  isPrintOnlyOnePerTansaction: boolean
  isHangingTicket: boolean
  isSmallTicket: boolean
  linkedItemId: number
  multiStartDate: Date
  multiEndDate: Date
  multiExpDays: number
  multiSalesPrice: number
  multiMemberPrice: number
  multiCostPrice: number
  multiGratuity: number
  multiServiceCharge: number
  multiPerSale: number

}

export interface ShopItem {
  id: number
  retailItemDetail: ShopItemDetails
  outletItem: any[]
  packagedItem: any[]
}

export interface UserAlertsModal {
  id: number;
  message: string;
  priority: number;
}

export interface Service {
  serviceDetail: ServiceDetail;
  serviceAddOns: ServiceAddOn[];
  serviceLocations: ServiceLocation[];
  serviceEquipments: ServiceEquipment[];
  serviceTherapists: ServiceTherapist[];
  serviceMedicalConditions: ServiceMedicalCondition[];
  servicePriceTypes: any[];
  serviceSeasonalDates: any[];
  serviceAvailableDays: ServiceAvailableDays;
  id: number;
}

export interface ServiceAvailableDays {
  id: number;
  serviceId: number;
  typeName: string;
  isAvailableOnSunday: boolean;
  isAvailableOnMonday: boolean;
  isAvailableOnTuesday: boolean;
  isAvailableOnWednesday: boolean;
  isAvailableOnThursday: boolean;
  isAvailableOnFriday: boolean;
  isAvailableOnSaturday: boolean;
  propertyId: number;
  subPropertyId: number;
}

export interface ServiceMedicalCondition {
  id: number;
  serviceId: number;
  medicalConditionId: number;
  propertyId: number;
  subPropertyId: number;
}

export interface ServiceTherapist {
  serviceId: number;
  therapistId: number;
  seniorityLevel?: string;
  propertyId: number;
  subPropertyId: number;
  id: number;
}

export interface ServiceEquipment {
  id: number;
  serviceId: number;
  equipmentId: number;
  propertyId: number;
  subPropertyId: number;
}

export interface ServiceLocation {
  id: number;
  serviceId: number;
  locationId: number;
  propertyId: number;
  subPropertyId: number;
}

export interface ServiceAddOn {
  id: number;
  serviceId: number;
  addOnId: number;
  propertyId: number;
  subPropertyId: number;
}

export interface ServiceDetail {
  code: string;
  description: string;
  serviceGroupId: number;
  effectiveFromDate: string;
  effectiveToDate: string;
  price: number;
  time: number;
  setupTime: number;
  breakDownTime: number;
  order: number;
  minimumAge: number;
  minimumGuest: number;
  maximumGuest: number;
  minimumStaff: number;
  maximumStaff: number;
  isAutoGratuity: boolean;
  gratuityPercent: number;
  gratuityAmount: number;
  isAutoServiceCharge: boolean;
  serviceChargePercent: number;
  serviceChargeAmount: number;
  isCommissionable: boolean;
  comments: string;
  policy: string;
  cancellationPolicy: string;
  requireStaffAtCheckin: boolean;
  isOffsite: boolean;
  isAvailableOnWeb: boolean;
  isInActive: boolean;
  retailItemId: number;
  propertyId: number;
  subPropertyId: number;
  id: number;
  colorCode: string;
}

export interface Therapist {
  code: string;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfHire: string;
  seniorityLevel: string;
  alsoKnownAs: string;
  allowGratuity: boolean;
  allowServiceCharge: boolean;
  allowCommission: boolean;
  isActive: boolean;
  listOrder: number;
  commissionClassId: number;
  propertyId: number;
  subPropertyId: number;
  id: number;
}




export interface GuestRetailTransactionHistory {
  transaction: Transaction[];
  itemDescription: any;
}



export interface Transaction {
  id: number;
  transactionData: TransactionData;
  transactionDetails: TransactionDetail[];
  transactionPayments: any[];
}

export interface TransactionDetail {
  id: number;
  transactionId: number;
  lineNumber: number;
  itemId: number;
  serviceId: number;
  staffId: number;
  staffType?: any;
  quantitySold: number;
  unitPrice: number;
  discount: number;
  commission?: any;
  serviceChargeGratuity?: any;
  tax: number;
  totalAmount: number;
  outletId: number;
  propertyId: number;
  subPropertyId: number;
  itemDescription? : string;
}

export interface TransactionData {
  id: number;
  ticketNumber: string;
  transactionType: string;
  status: string;
  transactionDate: string;
  clerkId: number;
  totalPrice: number;
  totalTax: number;
  totalAmount: number;
  guestId: number;
  memberId: number;
  comment: string;
  stayId: number;
  isTaxExempt: boolean;
  isVoided: boolean;
  outletId: number;
  gratuity: number;
  serviceCharge: number;
  discount: number;
  propertyId: number;
  subPropertyId: number;
}

export interface SystemConfig {
  id: number;
  switch: string;
  value: string | boolean;
  moduleId: number;
}
export interface SeasonalConfig {
  id: number;
  startDate: object;
  endDate: object;
  isApplyForAllDays: object;
  openTime: string;
  closeTime: string;
  seasonaldays: SeasonalDaysConfig[];
}
export interface ColorCodeConfig {
  id: number;
  status: string;
  switchName: string;
  colorCode: string;
}
export interface SeasonalDaysConfig {
  id: number;
  seasonalHourId: number;
  day: string;
  openTime: string;
  closeTime: string;
}
export interface PropertyConfig {
  propertyId: number;
  propertyName: string;
  zip: string;
  state: string;
  city: string;
  country: string;
  languageCode: string;
  tenantId: number;
  propertyCode: number;
  address1: string;
  address2: string;
  address3: string;
  propertyContacts: PhNumber[];
}
export interface PhNumber {
  id: number;
  number: number;
  propertyId: string;
  contactTypeId: string | number;
  clientId: number;
}

export interface UserBreakPoint {
  userRoleId: number;
  breakPointNumber: number;
  allow: boolean;
  view: boolean;
}
export interface UserName {
  id: number;
  firstName: string;
  lastName: string;
}

export interface ManagementData {
  location: SpaServiceLocation[];
  therapist: TherapistDetails[];
  service: SpaServices[];
  package: PackageDetail[];
  client: ClientDetail[];
  addOn: ServiceAddOns[];
  linkCode: LinkCode[];
  appointmentConfigurations: SystemConfiguration[];
  breakType: BreakType[];
}

export interface linkMultipackData {
  appointmentId: number;
  multipackId?: number;
  price: number;
  serviceCharge: number;
  gratuity: number;
  serviceChargePercentage: number;
  gratuityPercentage: number;
}


export interface StaffTransactionDetail {
  StaffId: number
  StaffType: string
}

export interface Role {
  id: number;
  roleName: string;
  active: boolean;
}

export interface RoleSetup {
  id?: number;
  description: string;
  active: boolean;
  TenantId?: number;
  propertyId?: number;
  subPropertyId?: number;
  productId : number[];
}

export interface CommissionTemplate {
  id: number;
  templateCode: string;
  templateName: string;
  commissionClassId: number;
  productId: number;
  splitDetail: CommissionTemplateSplit[];
}

export interface CommissionTemplateSplit {
  id: number;
  commissionTemplateId: number;
  levelDescription: string;
  percentage: any;
}



export interface popupConfig {
  operation: string;
  maxListOrder?: number;
  GridOperationType?: GridOperationType;
  fromScreen?: string;
}

export interface ImageData {
  id: number;
  referenceType: string;
  referenceId: number;
  sequenceNo: number;
  contentType: string;
  data: any[];
  thumbnailData: any[];
}

export interface CalendarGrid {
  timing: string;
  data: CalendarGridData[];
}

export interface CalendarGridData {
  spaDayout: string;
  noschedule: string;
  hdr: CalendarHeader[],
  tempdata: any[];
  data: any[];
}

export interface MoreFilterOptions {
  ShowMoreFilters: boolean;
  moreData: any;
  selectedMoreData: any;
  top: number;
  left: number;
  belongto: string;
  fromPage: string;
}


export interface CalendarHeader {
  id: number;
  title: string;
  subtitle: string;
  img: image;
}

export interface image {
  contentType: string;
  data: string;
}

export interface KeyValuePair {
  key: any;
  value: any;
}

export interface OverBookOptions {
  canOverbook: boolean;
  isClientOverbook: boolean;
  isTherapistOverbook: boolean;
  isLocationOverbook: boolean;
}

export interface clientMultiPack {
  id: number;
  retailItemId: number;
  transactionId: number;
  transactionDetailId: number;
  clientId: number;
  quanitity: number;
  remaining: number;
  linkedRetailItemId: number;
  soldOn: Date;
  startDate: Date;
  expirationDate: Date;
  salePrice: number;
  gratuity: number;
  serviceCharge: number;
  tax: number;
  isUnlimited: boolean;
  isReturned: boolean;
  isExpired: boolean;
}

export interface AppointmentGlobalSearch {
  searchText: string
  searchDate: boolean
  searchDateValue: string
}

export interface PackageMoveValidators {
  maxNoDays: number;
  minDate: Date;
  maxDate: Date;
  packageStartDate: Date;
  packageEndDate: Date;
  availableDays: PackageAvailableDays;
  yield?: PackageYield[];
  packageAppointmentDates: Date[];
}

export interface AppointmentFilterOutput {
  serviceViewModels: ServiceViewModel[];
  locationViewModels: LocationViewModel[];
  therapistViewModels: TherapistViewModel[];
  therapistLocationModels: TherapistLocationModel[];
}

export interface ServiceViewModel {
  id: number;
  code: string;
  description: string;
  serviceGroupDescription: string;
  serviceGroupId: number;
  isActive: boolean;
  effectiveFromDate: Date | string;
  effectiveToDate: Date | string;
  cancellationPolicy: string;
  price: number;
  duration: number;
  setupTime: number;
  breakDownTime: number;
  serviceComment: string;
  servicePolicy: string;
  minimumGuest: number;
  maximumGuest: number;
  minimumStaff: number;
  maximumStaff: number;
  isAutoGratuity: boolean;
  gratuityPercent: number;
  gratuityAmount: number;
  isAutoServiceCharge: boolean;
  serviceChargePercent: number;
  serviceChargeAmount: number;
  retailItemId: number;
  colorCode: string;
  isOffsite : boolean;
}

export interface LocationViewModel {
  serviceId: number;
  locationId: number;
  locationCode: string;
  locationDescription: string;
}

export interface TherapistViewModel {
  serviceId: number;
  therapistId: number;
  therapistName: string;
}
export interface TherapistLocationModel {
  therapistId: number;
  locationId: number;
}

export interface AppointmentWaitListClient {
  id: number;
  appointmentWaitListId: number;
  clientId: number;
  therapistId: number;
  comments: string;
  firstName?: string;
  lastName?: string;
  guestId?: any;
}

export interface AppointmentWaitList {
  id: number;
  startTime: Date;
  endTime: Date;
  serviceId: number;
  serviceGroupId: number;
  locationId: number;
  priority: number;
  comments: string;
  confirmed?: boolean;
  createdAt?: Date | string;
  createdBy?: number;
  modifiedAt?: Date | string;
  modifiedBy?: number;
  client: AppointmentWaitListClient[];
}

export interface WaitListAppointmentInfo {
  id: number;
  startTime: Date | string;
  endTime: Date | string;
  startDate: Date | string;
  endDate: Date | string;
  serviceId: number;
  serviceGroupId: number;
  selectedServiceGroup: number[];
  locationId: number;
  priority: number;
  comments: string;
  therapistHelperText?: string;
  client: AppointmentWaitListClient[];
}

export enum WaitListPriority {
  High = 1,
  Medium = 2,
  Low = 3
}

export interface MedicalConditionWarning {
  MedicalConditionCode: string;
  MedicalDescription: string;
  MedicalConditionMatched: string;
  MedicalWarning: string;
}

export interface ServiceClientMedicalWarning {
  ServiceId: number;
  ClientId: number;
  MedicalConditionWarning: MedicalConditionWarning[];
}
export interface AppointmentPrice {
  serviceCharge: number;
  gratuity: number;
  totalAmount: number;
  price: number;
  serviceChargePercent: number;
  gratuityPercent: number;
}

export interface asideFilterModel {
  id: number;
  name: string;
  isAll: boolean;
}

export interface OutletSubProperty {
  subPropertyID: number
  subPropertyCode: string
  subPropertyName: string
  propertyID: number
  isActive: boolean
  profitCenter?:string
}



export interface NoDataModel {
  inputText: string,
  noDataId: noRecordsType,
  noDataFontSize?: number
}

export interface Device {
  name: string,
  handle: string
}


//Request Model for GetPaymentHandles:
export interface FeaturesConfigurations {
  configurationKey: string;
  configurationValue: string;
  propertyFeatureId: number;
}


export interface HandleRequest {
  tenderId: string; // (Required)
  inquiryInfo?: Inquiry; // (Required)
  originalTenderId?: number;
}


export interface CMSHandleRequest extends HandleRequest {
  inquirerInformation: InquirerInformation;
  profitCenterId: string;
}

export interface InquirerInformation {
  terminalId?: string; // (Required)
  store?: string;
  division?: string;
  enterprise?: string;
  customer?: string;
  mealPeriod?: string;
  coverCount?: string;
  orderType?: string;
  orderNumber?: string; // (Required)
  employeeId?: string;
  profitCenter: string; //(Required)
  profitCenterName?: string; //(Required)
  tenderId?: string; //(Required)
  zipcode?: string;
  isPartialTenderAllowed?: boolean;
  financialBins?: Array<FinancialBin>
}

export interface FinancialBin {
  Id: string,
  type: string,
  amt: string
}


export interface Inquiry {
  id?: string;
  type?: string; //(Required)
  name?: string;
  cardData?: CardData;
  TenderId?: string;
  manualCardEntry?: boolean;
  isPartialTenderAllowed?: boolean;
}

export interface CardData {
  track1?: string;
  track2?: string
  encryptedData: string;
}

//-----------------------------------------------------------------------------------------
//Response Model for GetPaymentHandles:

export interface HandleResponse {
  status: string;
  errorMessage: string;
  paymentHandle: PayHandle[];
  hostName?: string;
  agentVersion?: string;
  ipAddress?: string;
}

export interface PayHandle {
  handle: string;
  inquiryInfo: Inquiry; //(as passed in request)
  name: string;
  type: string;
  balance: number;
  isBalanceVisible: boolean
  isPartialTenderAllowed: boolean;
  isRefundable: boolean;
  additionalAttributes: object;
  allowedAPIs: string[];
}

export interface HandleData {
  configurationId: number;
  adapter: string;
  id: string;
  type: string;
  name: string;
}

export interface ClientCreditCardInfo {
  id: number;
  clientId: number;
  tokenTransId: number;
  isActive: boolean;
  createdTime: Date | string | null;
}
export interface CreateTokenRequest {
  handle: string;
  inquirerInfo: Inquiry;
}

export interface TokentransactionInfo {
  payAgentId: string;
  status: string;
  account: Account;
  cardInfo: PayAgentCardInfo;
  transactionDetails: string;
  transactionKey: string;
  hostName?: string;
  agentVersion?: string;
  ipAddress?: string;
  requestId?: string;
  gatewayResponse?: string;
}

export interface PaymentTransactionInfo {
  payAgentId: string;
  status: string;
  account: Account;
  transactionDetails: string;
  transactionKey: string;
}

export interface StoreTokenRequest {
  payAgentResponse: PaymentTransactionInfo;
  cardInfo: CardInfo;
  tenderId:number;
  sourceType? : string;
  sourceTypeId? :string;
  roomNumber? :string;
  folioNumber?:string;
  guestGuid?:string;
  hostName?: string;
  agentVersion?: string;
  ipAddress?: string;
  requestId?: string;
  gatewayResponse?: string;
  outletId?: number;
  propertyId?: number;
}

export interface TokenRequest {
  PayAgentResponse: TokentransactionInfo;
}

export interface Account {
  id: string;
  name: string;
  type: string;
}

export interface PayAgentCardInfo {
  entryMode: string;
  issuer: string;
  cardExpiration: string;
  cardType: string;
  token:string;
}

export interface CardInfo {
  cardNumber: string;
  cardHolderName: string;
  entryMode: string;
  issuerType: string;
  cardExpiration: string;
  cardType: string;
}

export interface PaymentBaseResponse {
  transactionId: number,
  status: boolean,
  errorMessage: string
}

export enum PaymentMethods {
  Cash = 1,
  CreditCard = 2,
  RoomCharge = 3,
  PendingSettlement = 4,
  IDTECH = 5,
  RainCheck = 6,
  CardOnFile = 22,
  ARPost = 7,
  RedeemPoint = 8,
  CreditBook = 9,
  CompRedemption = 10,
  OfferRedemption = 11,
  V1GiftCard = 12,
  ExternalGiftCard = 13,
  V1GiftCardIdTech = 14,
  ExternalGiftCardIdTech = 15,
  ARAcctPost = 16,
  AgilysysGiftCard = 17,
  AgilysysGiftCardIdTech = 18,
  CompSlipRedemption = 19,
  NONINTEGRATEDCREDITCARD = 26,
  GuaranteeMethod = 99,
  Other = 44
}

export interface PaymentMethod {
  id: number;
  isActive?: boolean;
  isDefault?: boolean;
  listOrder?: number;
  paymentMethod?: string;
  paymentMethodId?: number;
  paymentTypeId: number;
  paymentTypeEqualant?: number;
  type?: string;
  isDisabled?: boolean;
  allowEarn?: boolean;
  postTypeId?: number;
  requireComments?: boolean;
  noOfReceipts?:number;
  requireReceipts?:boolean;
  financialBinLevel?: number;
  isSurcharge?: boolean;
  isPercent?: boolean;
  surchargeValue?: number;
  parentMethodId?: number;
  parentTypeId?: number;
  isCloned?: boolean;
  additionalConfigurations?: string;
  requireSignatureCapture?: boolean;
  bucketCode?:string;
  bucketName?:string;
  tenderReducesDiscount?: boolean;
  isAutoRemoveTax?: boolean;
  corpId?: number;
  requireCashDrawer?: boolean;
}


//-----------------------------------------------------------------------------------------
//Response Model for GetPaymentHandles:

export interface HandleResponse {
  status: string;
  errorMessage: string;
  paymentHandle: PayHandle[];
}

export const IDTechHandle = {
  handle:"IDTech SRED Key",
  name:"IDTech SRED Key"
}

export const RGuestBookSwitch ={
  RBOOK_BOOKINGDAYSOUT:'RBOOK_BOOKING_DAYS_OUT',
  RBOOK_ALLOWAPPOINTMENTEDIT:'RBOOK_ALLOW_APPOINTMENT_EDIT',
  RBOOK_ALLOWAPPOINTMENTCANCEL:'RBOOK_ALLOW_APPOINTMENT_CANCEL',
  RBOOK_CANCELDAYSOUT:'RBOOK_CANCEL_DAYS_OUT',
  RBOOK_CANCELPOLICY:'RBOOK_CANCEL_POLICY',
  RBOOK_REQUIREAPPOINTMENTDEPOSIT:'RBOOK_REQUIRE_APPOINTMENT_DEPOSIT',
  RBOOK_REQUIREAPPOINTMENTDEPOSITTYPE:'RBOOK_REQUIRE_APPOINTMENT_DEPOSIT_TYPE',
  RBOOK_INCLUDEGRATUITY:'RBOOK_INCLUDE_GRATUITY',
  RBOOK_INCLUDESERVICECHARGE:'RBOOK_INCLUDE_SERVICE_CHARGE',
  RBOOK_THERAPISTDISPLAY:'RBOOK_THERAPIST_DISPLAY',
  RBOOK_THERAPISTDISPLAYIMAGE:'RBOOK_DISPLAY_THERAPIST_IMAGE',
  RBOOK_DEPOSITVALUE:'RBOOK_DEPOSIT_VALUE',
  RBOOK_OUTLET:'RBOOK_OUTLET',
  RBOOK_IS_DEPOSITCUSTOMPERCENTAGE:'RBOOK_IS_DEPOSIT_CUSTOM_PERCENTAGE',
  RBOOK_TERMINAL:'RBOOK_TERMINAL'
}


export const DefaultFieldConfigurationSwitches = {
  defaultPhoneTypeSwitch : 'DEFAULT_PHONE_TYPE',
  defaultEmailTypeSwitch : 'DEFAULT_EMAIL_TYPE',
  defaultCountryPhoneCodeSwitch : 'DEFAULT_COUNTRY_CODE'
}

export const DataTypeConstants ={
  Boolean:'Boolean',
  Int32:'Int32'
}

export const IDTech = {
  id: "idtech"
}

export enum PaymentErrorCodes {
  ClientInvalidRequest = 9000,
  ClientInvalidConfiguration = 9001,
  ClientInvalidDeviceGuid = 9002,
  ClientInvalidTransactionId = 9003,
  ClientInvalidToken = 9004,
  ClientInvalidTransactionState = 9005,
  ClientInvalidTransactionFollowOnData = 9006,
  ClientAbortedRequest = 9099,
  CardError = 9500,
  CardErrorUnsupportedCardType = 9501,
  CardErrorInvalidAccountNumber = 9502,
  CardErrorInvalidExpirationDate = 9503,
  CardErrorUnsupportedCardIssuer = 9504,
  CardDeclined = 9600,
  CardDeclinedExpired = 9601,
  CardDeclinedLimit = 9602,
  CardDeclinedReferral = 9603,
  CardDeclinedCvv = 9604,
  CardDeclinedAvs = 9605,
  CardDeclinedChipDecline = 9606,
  DeviceError = 9200,
  DeviceInvalidConfiguration = 9201,
  DeviceNotAvailable = 9202,
  DeviceNotReady = 9203,
  DeviceOperationUnsupported = 9204,
  DeviceOperationAborted = 9205,
  DeviceReadWithoutPrompt = 9206,
  DeviceNoDataAvailable = 9207,
  DeviceInvalidData = 9208,
  DeviceUserPressedCancel = 9209,
  GatewayError = 9300,
  GatewayAuthenticationFailed = 9301,
  GatewayUnavailable = 9302,
  GatewayProcessorUnavailable = 9303,
  GatewayUnsupportedRequest = 9304,
  ServerError = 9100,
  ServerUnsupportedRequest = 9101,
  ServerInvalidConfiguration = 9102,
  TransactionError = 9400,
  TransactionNotFound = 9401,
  TransactionInvalidState = 9402,
  TransactionDuplicate = 9403,
  //Added for IG PMAgent Timeout
  TransactionTimeOut = 408,
  PaymentManagerTimedOut=10060,

  //GiftCard related Codes
  CardNumberNotValid = 9404,
  CardNumberNotActive = 9505,
  CardPINInvalid = 9506,
  CardAlreadyIssued = 9507,
  CardAlreadyInActive = 9509,
  CardAlreadyActive = 9510,
  CardExpired = 9516,

  PayAgentDissabledInPaymentManager = 111101,
  PayAgentNotFoundInPaymentManager = 111102,
  PayAgentNotActivatedInPaymentManager = 111103,
}

export interface GlobalConfiguration {
  Switches: SwitchConfig;
}

export interface SwitchConfig {
  GuestProfileRequired: boolean
}

export interface VATConfiguration {
  id: number;
  propertyId: number;
  vatNumber: string;
  serviceChargeOnGross: boolean;
  serviceChargeOnNet: boolean;
  vatEnbledForServiceCharge: boolean;
  serviceChargeVATId: number;
  gratuityOnGross: boolean;
  gratuityOnNet: boolean;
  itemCommissionOnGross: boolean;
  itemCommissionOnNet: boolean;
  revenueCommissionOnGross: boolean;
  revenueCommissionOnNet: boolean;
}

export enum GrossNetEnum {
  Gross = 1,
  Net = 2
}

export interface CheckData {
  gratuity: number;
  serviceCharge: number;
  checkNumber:number;
  terminalId?:number;
}

export interface TransactionAdapter {
  lineItems: LineItem[],
  checkData: CheckData;
  checkJSON: string;
}
export interface LineItem {
  externalPOSItemId: number;
  quantity: number;
  unitPrice: number;
  netUnitPrice?: number;
  vat?: number;
}

export interface PriceModel {
  priceForGratuity: number;
  priceForServiceCharge: number;
}



export interface SaleByTokenResponse {
  transactionId?: number;
  status?: boolean;
  errorMessage?: string;
  errorCode?: string;
  paymentManagerResponse: PMPayResponse;
}

export interface PMPayResponse {
  status?: string;
  transactionId?: string;
  transactionTime: Date | string;
  amount: Amount;
  inquirerInfo: InquirerInformation;
  clientInfo?: ClientInfo;
  errorMessage?: string;
  totalAuthorizedAmount:number;
}

export interface SaleByTokenRequest {
  inquirerInfo: InquirerInformation;
  transactionId: number;
  amount: number;
  sourceType : string;
  sourceTypeId :string;
  roomNumber :string;
  folioNumber:string;
  hostName?: string;
  agentVersion?: string;
  ipAddress?: string;
}

export interface Amount {
  requestAmount: number; // (Required)
  authorisedAmount?: number;
  tipAmount?: number;
  taxAmount?: number;
  saleAmount?: number;
}

export interface AuthByTokenRequest {
  inquirerInfo: InquirerInformation;
  transactionId: number;
  amount: number;
  sourceType : string;
  sourceTypeId :string;
  roomNumber :string;
  folioNumber:string;
}

export interface AuthByTokenResponse {
  transactionId?: number;
  status?: boolean;
  errorMessage?: string;
  errorCode?: string;
  paymentManagerResponse: PMPayResponse;
}

export interface AuthRequest {
  handle: string; //(Required)(Encrypted data)
  amount: Amount; //(Required)
  inquirerInfo: InquirerInformation; //(Required)
}

export interface DisplayInformation {
  id: string;
  name: string;
  type: string;
}

export const enum GatewayConfigurationType {
  None = 0,
  CreditCard,
  GiftCard
}

export interface AuthResponse {
  payAgentId: string;
  status: string;
  account: DisplayInformation;
  amount: Amount;
  transactionDetails: string; //(Encrypted)
  transactionKey: string;
  errorMessage: string;
  cardInfo: PayAgentCardInfo;
  hostName?: string;
  agentVersion?: string;
  ipAddress?: string;
}

export interface VoucherRedemptionResponse {
  transactionId: string;
  voucherId: string;
  voucherType: string;
  name: string;
  voucherAmount: number;
}

export interface Voucher {
  voucherId: string;
  voucherType: string;
  name: string;
  voucherAmount: number;
  voucherExpirationDate: string | Date;
  vendorType: string;
}

export interface Setting<T>  {
  id: number;
  moduleName: string;
  screenName: string;
  configValue: T;
  defaultValue: T;
}

export interface ValidatePayRequest {
  tenderId: number;
  payAgentResponse: PayAgentResponse;
  cardInfo: CardInfo;
  amount: number;
  aCESPaymentRecord:ACESPaymentRecord;
  cMSPaymentRecord: CMSPaymentDetails;
  giftcardPaymentRecord: GiftCardPaymentRecord;
  aRPostingPaymentRecord: ARPostPaymentResponse;
  hostName?: string;
  agentVersion?: string;
  ipAddress?: string;
}

export interface SaleRequest {
  handle: string; //(Required)(Encrypted data)
  amount: Amount; //(Required)
  inquirerInfo: InquirerInformation; //(Required)
}

export interface SaleResponse {
  payAgentId: string;
  status: string;
  account: DisplayInformation;
  amount: Amount;
  transactionDetails: string; //(Encrypted)
  transactionKey: string;
  errorMessage: string;
  cardInfo: PayAgentCardInfo;
  hostName?: string;
  agentVersion?: string;
  ipAddress?: string;
}

export interface ValidateAuthRequest {
  tenderId: number;
  payAgentResponse: PayAgentResponse;
  cardInfo: CardInfo;
  amount: number;
  sourceType : string;
  sourceTypeId :string;
  roomNumber :string;
  folioNumber:string;
  hostName?: string;
  agentVersion?: string;
  ipAddress?: string;
}

export const HttpResponseStatus = {
  Success: "success",
  Failure: "failure"
}