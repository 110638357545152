import { Injectable } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { RadioOptions } from 'src/app/common/Models/ag-models';
import { EventSchedulerInterval } from '../event-scheduler.model';
import { DaysModel } from 'src/app/common/localization/localization.model';

@Injectable()
export class NotificationScheduleBusiness {
  captions: any;

  /**
   * Class contains business logic and transformation between the UI and API model.
   * One or more data services can be injected to get the data for UI binding.
   */
  constructor(private localization: Localization) {
    this.captions = this.localization.captions.ReportSchedular;
  }

  getEventOptionsList(): RadioOptions[] {
    return [
      {
        id: 1,
        value: this.captions.lbl_recurrenceAutoSchedule,
        viewValue: this.captions.lbl_recurrenceAutoSchedule
      },
      {
        id: 2,
        value: this.captions.lbl_runOnDemand,
        viewValue: this.captions.lbl_runOnDemand
      }
    ];
  }

  getDaysArray() : DaysModel[]{

    const returnArr: DaysModel[] = [];

      returnArr.push(
        {
          id: 0,
          short: 'Sun',
          long: 'Sunday',
          code: 'Sun',
          longCode: 'Sunday',
        },
        {
          id: 1,
          short: 'Mon',
          long: 'Monday',
          code: 'Mon',
          longCode: 'Monday',
        },
        {
          id: 2,
          short: 'Tue',
          long: 'Tuesday',
          code: 'Tue',
          longCode: 'Tuesday',
        },
        {
          id: 3,
          short: 'Wed',
          long: 'Wednesday',
          code: 'Wed',
          longCode: 'Wednesday',
        },
        {
          id: 4,
          short: 'Thu',
          long: 'Thursday',
          code: 'Thu',
          longCode: 'Thursday',
        },
        {
          id: 5,
          short: 'Fri',
          long: 'Friday',
          code: 'Fri',
          longCode: 'Friday',
        },
        {
          id: 6,
          short: 'Sat',
          long: 'Saturday',
          code: 'Sat',
          longCode: 'Saturday',
        }
      );
    return returnArr;
  }

  getEndOptionsList(): RadioOptions[] {
    return [
      {
        id: 1,
        value: this.captions.lbl_onThisDay,
        viewValue: this.captions.lbl_onThisDay
      },
      {
        id: 2,
        value: this.captions.lbl_noEndDate,
        viewValue: this.captions.lbl_noEndDate
      }
    ];
  }

  getMonthDayOptionsList(): RadioOptions[] {
    return [
      {
        id: 1,
        value: this.captions.lbl_day,
        viewValue: this.captions.lbl_day
      },
      {
        id: 2,
        value: this.captions.lbl_first,
        viewValue: this.captions.lbl_first
      },
      {
        id: 3,
        value: this.captions.lbl_last,
        viewValue: this.captions.lbl_last
      }
    ];
  }

  generateScheduleButtons(): any[] {
    return [
      {
        id: EventSchedulerInterval.Daily,
        value: EventSchedulerInterval.Daily.toString(),
        viewValue: this.captions.lbl_daily,
        isSelected: false
      },
      {
        id: EventSchedulerInterval.Weekly,
        value: EventSchedulerInterval.Weekly.toString(),
        viewValue: this.captions.lbl_weekly,
        isSelected: false
      },
      {
        id: EventSchedulerInterval.Monthly,
        value: EventSchedulerInterval.Monthly.toString(),
        viewValue: this.captions.lbl_monthly,
        isSelected: false
      },
      {
        id: EventSchedulerInterval.Yearly,
        value: EventSchedulerInterval.Yearly.toString(),
        viewValue: this.captions.lbl_yearly,
        isSelected: false
      }
    ];
  }

}
