import { Injectable } from '@angular/core';
import { ZebraPrintService } from './zebra-print.service';
import { ZebraPrinters, ZebraPrinter, BarcodePrintRequest, BarcodePrintData, PrintLabelsData } from '../../retail/retail.modals';
import { RetailLocalization } from '../common/localization/retail-localization';
import { Router } from '@angular/router';
import { CustomCurrencyPipe } from '../common/localization/currency.pipe';
import { BARCODE_PRINT_STYLES, JSBARCODE_CDN, BARCODE_SCRIPT } from './barcode-print.service.constants';

@Injectable()
export class BarcodePrintService {

  PrintLabelsData: PrintLabelsData[];
  MIN_START_POSITION: number = 1;
  MAX_START_POSITION: number = 30;
  price: string = this.localization.captions.retailsetup.Price;

  LABELS_PER_SHEET: number = 30;
  COLUMNS_PER_ROW: number = 3;
  ROWS_PER_SHEET: number = 10;

  ZebraPrinters: ZebraPrinters = null;
  HangingTicketsPrintData: string[];
  SmallStickersPrintData: string[];
  html: string;
  useModifiedSmallSticker: boolean = false;
  useModifiedSmallStickerV2: boolean = false;
  constructor(private router: Router, private zpl: ZebraPrintService, private localization: RetailLocalization, private currency: CustomCurrencyPipe) {

  }

  async getLocalDevices(): Promise<ZebraPrinters> {
    if (this.ZebraPrinters == null) {
      try {
        this.ZebraPrinters = await this.zpl.getLocalDevices();
      } catch (e) {
        this.ZebraPrinters = { printer: [] };
        console.log("Error in Get local printer devices" + e);
      }
      return this.ZebraPrinters;
    } else {
      return this.ZebraPrinters;
    }
  }

  async printBarcodeLabels(PrintItems: any[], startPosition?: number, smallStickersPrinterName?: string, hangingTicketsPrinterName?: string): Promise<string> {

    console.log("In printBarcodeLabels");
    console.log(PrintItems);
    console.log(`startPosition: ${startPosition}`);
    console.log(`smallStickersPrinterName: ${smallStickersPrinterName}`);
    console.log(`hangingTicketsPrinterName: ${hangingTicketsPrinterName}`);
    let propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
    var propConfigUpperCase = {}
    Object.entries(propConfig)?.forEach(([k, v]) => {propConfigUpperCase[k?.toUpperCase()] = v})
    propConfig = propConfigUpperCase;
    this.useModifiedSmallSticker = (propConfig?.USEMODIFIEDSMALLSTICKER?.trim()?.toLowerCase() ?? "false") == "true"  
    this.useModifiedSmallStickerV2 = (propConfig?.USEMODIFIEDSMALLSTICKERV2?.trim()?.toLowerCase() ?? "false") == "true"  
    


    let foundZebraPrinters: boolean = false;
    let foundSmallStickersPrinter: boolean = false;
    let foundhangingTicketsPrinter: boolean = false;
    let smallStickersPrinter: ZebraPrinter = new ZebraPrinter();
    let hangingTicketsPrinter: ZebraPrinter = new ZebraPrinter();

    this.PrintLabelsData = [];
    this.HangingTicketsPrintData = [];
    this.SmallStickersPrintData = [];

    PrintItems.forEach(item => {
      this.processPrintItems(item)
    });

    // Print labels before stickers in case Zebra printers not available
    if (this.PrintLabelsData.length > 0) {
      if (startPosition < this.MIN_START_POSITION) {
        startPosition = this.MIN_START_POSITION;
      }
      if (startPosition > this.MAX_START_POSITION) {
        startPosition = this.MAX_START_POSITION;
      }
      // use startPosition to add blank entries to PrintLabelsData, then navigate to PrintLayoutComponent
      let blankLabel = {} as PrintLabelsData;
      blankLabel.outletName = '';
      blankLabel.itemDescription = '';
      blankLabel.itemPrice = 0;
      blankLabel.barcode = '';
      blankLabel.printQuantity = 1;

      for (var i: number = 1; i < startPosition; i++) {
        this.PrintLabelsData.unshift(blankLabel);
      }
      let BarcodeMax = this.getMaxBarcode(PrintItems);
      this.validateBarcode(BarcodeMax); 
      let printData: string = (this.generateBarcodeLabelsHTML(this.PrintLabelsData));

      this.html = `
      <html>
      <head>
      <style>${BARCODE_PRINT_STYLES}</style>
      <script src="${JSBARCODE_CDN}"></script>
      <script>${BARCODE_SCRIPT}</script>
      </head>
      <body onload="run()">
      <div class="sheet">
      ${printData}
      </div>
      </body>
      </html>`; 
      this.openHtmlInNewTab(this.html);
      // let tab = window.open('', '_blank', 'height=' + screen.height + ',width=' + screen.width); //to open print in same window
      // tab.document.write(this.html);
      // tab.document.close();
 
    }

    let zebraPrinters = await this.getLocalDevices();
    // validate specified Zebra printers (from user/session configuration) with available printers before continuing
    foundZebraPrinters = (zebraPrinters && zebraPrinters.printer !== undefined && zebraPrinters.printer.length > 0);

    if (foundZebraPrinters) {
      if (Boolean(smallStickersPrinterName) || Boolean(hangingTicketsPrinterName)) {
        if (foundZebraPrinters && zebraPrinters.printer.length > 0) {
          for (var p of zebraPrinters.printer) {
            if (p.name == smallStickersPrinterName) {
              foundSmallStickersPrinter = true;
              smallStickersPrinter = Object.assign(smallStickersPrinter, p);
            }
            if (p.name == hangingTicketsPrinterName) {
              foundhangingTicketsPrinter = true;
              hangingTicketsPrinter = Object.assign(hangingTicketsPrinter, p);
            }
          }
        } else {
          return new Promise(reject => {
            reject('ERROR: Unable to get list of connected Zebra printers for validation');
          });
        }
      }
    }

    if (foundZebraPrinters && this.HangingTicketsPrintData.length > 0) {
      if (foundhangingTicketsPrinter) {
        this.sendToZebraPrinter(hangingTicketsPrinter, this.HangingTicketsPrintData.toString());
      } else {
        return new Promise(reject => {
          reject(`ERROR: Hanging tickets printer: ${hangingTicketsPrinter} not found`);
        });
      }
    }

    if (foundZebraPrinters && this.SmallStickersPrintData.length > 0) {
      if (foundSmallStickersPrinter) {
        this.sendToZebraPrinter(smallStickersPrinter, this.SmallStickersPrintData.toString());
      } else {
        return new Promise(reject => {
          reject(`ERROR: Small stickers printer: ${smallStickersPrinter} not found`);
        });
      }
    }
    this.resetSheetValues();
    return new Promise(resolve => {
      resolve('printBarcodeLabels completed');
    });
  }
  openHtmlInNewTab(htmlContent) {
        const blob = new Blob([htmlContent], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        const newTab = window.open(url, '_blank', 'noreferrer');
        if (newTab) {
            newTab.onload = function() {
                URL.revokeObjectURL(url);
            };
            newTab.focus();
        } 
  }
  processPrintItems(item: BarcodePrintData) {
    if (item.isHangingTicket) {
      this.HangingTicketsPrintData.push(this.formatHangingTagLabelZpl(item));
    }
    if (item.isSmallSticker) {
      this.SmallStickersPrintData.push(this.formatSmallStickerLabelZpl(item));
    }
    if (!item.isHangingTicket && !item.isSmallSticker) {
      this.PrintLabelsData.push(this.formatPrintLabelsData(item));
    }
  }

  formatPrintLabelsData(item: BarcodePrintData): PrintLabelsData {

    let LabelData = {} as PrintLabelsData;
    LabelData.outletName = item.outletName;
    LabelData.itemDescription = item.itemDescription;
    LabelData.itemPrice = item.itemPrice;
    LabelData.barcode = item.barcode;
    LabelData.printQuantity = item.printQuantity;

    return LabelData;
  }
 

  formatHangingTagLabelZpl(data: BarcodePrintData): string {
    var OutletName = data.outletName;
    var ItemDescription = data.itemDescription;
    var ItemPrice = data.itemPrice;
    var Barcode = data.barcode;
    var PrintQuantity = data.printQuantity;

    const LocalizedItemPrice: string = this.currency.transform(ItemPrice);

    return `
  ^XA
  ^PW356
  ^FT21,97^A0N,28,28^FH\^FD${OutletName}^FS
  ^FT21,130^A0N,28,28^FH\^FD${ItemDescription}^FS
  ^FT21,284^A0N,28,28^FH\^FD${this.price}: ${LocalizedItemPrice}^FS
  ^BY2,2,78^FT21,375^B3N,N,,Y,N^FD${Barcode}^FS
  ^PQ${PrintQuantity},0,1,Y
  ^XZ`; 
  }

  formatSmallStickerLabelZpl(data: BarcodePrintData): string {
    var OutletName = data.outletName;
    var ItemDescription = data.itemDescription;
    var ItemPrice = data.itemPrice;
    var Barcode = data.barcode;
    var PrintQuantity = data.printQuantity;
    let LocalizedItemPrice: string = this.currency.transform(ItemPrice);
    LocalizedItemPrice = LocalizedItemPrice.replace(/\s+/g, ' ');
    let BarcodeWidth: number = this.useModifiedSmallSticker ? 1 : 2;
    let paperwidth = '253';
 
    if(Barcode.length <=8 && this.useModifiedSmallStickerV2){
      paperwidth = '450';
      BarcodeWidth = 2;
    }

    return `
  ^XA
  ^PW${paperwidth}
  ^FT14,55^A0N,20,20^FH\^FD${OutletName}^FS
  ^FT14,80^A0N,20,20^FH\^FD${ItemDescription}^FS
  ^FT14,120^A0N,20,20^FH\^FD${this.price}: ${LocalizedItemPrice}^FS
  ^BY${BarcodeWidth},2.5,55^FT14,185,0^B3N,N,,Y,N^FD${Barcode}^FS
  ^PQ${PrintQuantity},0,1,Y
  ^XZ`;
  }

  sendToZebraPrinter(device: ZebraPrinter, printdata: string): void {
    var barcodePrintRequest = new BarcodePrintRequest;
    barcodePrintRequest.device = device;
    console.dir(barcodePrintRequest.device);
    barcodePrintRequest.data = printdata;
    this.zpl.sendLabels(barcodePrintRequest)
      .then(o => {
        console.log('Return from sendLabels call')
      }).catch(err => {
        let error = 'Error from sendLabels call';
        if (err)
          error = error + ' ' + err;
        console.log(error);
      });
  }

  private generateBarcodeLabelsHTML(printData: PrintLabelsData[]): string {
    var data = [];
    var job: any[];

    // Expand print data
    for (let prtdt of printData) {
      let p = prtdt;
      for (var j = 0; j < p.printQuantity; j += 1) {
        data.push(p);
      }
    }

    // Pad with blank labels to complete current row
    let blankLabel = {} as PrintLabelsData;
    blankLabel.outletName = '';
    blankLabel.itemDescription = '';
    blankLabel.itemPrice = 0;
    blankLabel.barcode = '';
    blankLabel.printQuantity = 1;

    while (data.length % this.COLUMNS_PER_ROW != 0) {
      data.push(blankLabel);
    }

    // Generate HTML
    var index: number = 0;
    job = [];
    for (var j = 0; j < data.length; j += this.LABELS_PER_SHEET) {
      var page = [];
      while (page.length < this.ROWS_PER_SHEET) {
        var row = [];
        while (row.length < this.COLUMNS_PER_ROW) {
          index = j + (page.length * this.COLUMNS_PER_ROW) + row.length;
          var label = data[index];
          if (!label) {
            break;
          }
          let barcodeHtml: string = "";
          if (label.barcode !== "") {
            barcodeHtml = `
            <svg class="jsbarcode"
            jsbarcode-format="CODE128"
            jsbarcode-value="${label.barcode}"
            jsbarcode-displayvalue="true"
            jsbarcode-fontsize="12"
            jsbarcode-height="18"
            jsbarcode-textposition="bottom"
            jsbarcode-margintop="5"
            jsbarcode-marginbottom="5"
            jsbarcode-marginleft="5"
            jsbarcode-marginright="5"
            jsbarcode-width="1.5"
            </svg>
            `;
          }
          let priceHtml: string = "";
          if (label.itemPrice > 0) {
            let itemPrice = this.localization.localizeCurrency(label.itemPrice);
            itemPrice = itemPrice.replace(/\s+/g, ' ');
            priceHtml = `${this.price}: ${itemPrice}`;
          }
          let labelHtml: string = (`
          <div class="description">${label.itemDescription}</div>
          <div class="barcode">${barcodeHtml}</div>
          <div class="price">${priceHtml}</div>
          `);
          row.push(`<td>${labelHtml}</td>`);
        }
        let rowHtml: string = row.join("");
        page.push(`<tr>${rowHtml}</tr>`);
      }
      let pageHtml: string = page.join("");
      job.push(`<div><table>${pageHtml}</table></div>`);
    }
    return job.join("");
  }

  private resetSheetValues (){
    this.LABELS_PER_SHEET = 30;
    this.COLUMNS_PER_ROW= 3;
    this.ROWS_PER_SHEET = 10;
  }

  private validateBarcode(barcode: string) {
    let barcodeValidResult = this.isBarcodeValid(barcode);
    if (barcodeValidResult.isAlphanumeric && !barcodeValidResult.isNumeric) {
      // it's alphanumeric (contains letters and numbers)
      if(barcode.length > 24) {
        this.COLUMNS_PER_ROW = 1;
      }else if (barcode.length > 18 && barcode.length <= 24) {
        this.COLUMNS_PER_ROW = 2;
      }else {
        this.COLUMNS_PER_ROW = 3;
      }
    }
    if (barcodeValidResult.isNumeric) {
      //it's numeric
      if(barcode.length > 35) {
        this.COLUMNS_PER_ROW = 1;
      }else if (barcode.length > 25 && barcode.length <= 35) {
        this.COLUMNS_PER_ROW = 2;
      } else {
        this.COLUMNS_PER_ROW = 3;
      }
    }
    this.LABELS_PER_SHEET = this.COLUMNS_PER_ROW * this.ROWS_PER_SHEET
  }

  private getMaxBarcode(items) {
    let maxBarcode = '';
    items.forEach(item => {
      const barcode = item.barcode;
      let barcodeValidResult = this.isBarcodeValid(barcode);
      if (barcodeValidResult.isNumeric && barcode.length > maxBarcode.length) {
        maxBarcode = barcode;
      } 
      if (barcodeValidResult.isAlphanumeric && !barcodeValidResult.isNumeric && barcode.length > maxBarcode.length) {
        maxBarcode = barcode;
      }
      //If Item description length exceeds 120, Rows will reduce to 8. 80 - 120 characters, rows will reduce to 9.
      if(item?.itemDescription?.length >= 120) {
        this.ROWS_PER_SHEET = 8;
      }
      else if(item?.itemDescription?.length >= 80) {
        this.ROWS_PER_SHEET = 9;
      }
    });
    return maxBarcode;
  }

  private isBarcodeValid(barcode: string): { isNumeric: boolean; isAlphanumeric: boolean } {
    const isNumeric = /^\d+$/.test(barcode);
    const isAlphanumeric = /^[a-zA-Z0-9]+$/.test(barcode);
    return { isNumeric, isAlphanumeric };
  }
}
